import { useSelector } from 'react-redux';
import { about_us } from '../../assets';
import translate from '../../Services/translate';

const AboutUs = () => {

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   
   return (
      <div className = 'px-3 sm:px-5'>
         <div className = 'pb-1 mb-2 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-start items-center'>
            <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(125) }</h2>
         </div>

         <div className = 'sm:flex px-1 sm:px-3 pt-3'>

            <div className = 'w-full sm:w-[38%] sm:pr-4'>
               <img src = { about_us } alt = '' className = 'w-full object-cover'/>
            </div>

            <div className = 'sm:pl-6 pt-3 sm:pt-0 text-gray-500 sm:w-[62%]'>
               <p className = 'indent-7'> Auction Ethiopia S.C. is a Licensed Ethiopian-based Auction Company specializing in on-line auctions of all types. It was established by expertise of a highly accredited team of licensed auctioneers and technologist with extensive technology-base hand on experiences in local and international marketing trend. Our firm is a full-service auction company with specialist in every facet of the auction in Ethiopia and international marketing industry. It brings an automated strategy to maximize value through our secured platform and handle a wide range of current and future auction needs, in items or services marketing. We are dedicated and proud to provide a secure and reliable auction management process for our clients (individuals or enterprises) in their pipeline from listing contract signing to successful sale.</p>

               <p className = 'indent-7 pt-4'>The primary focus of Auction Ethiopia is providing a secured platform to bidders and tenders of all types to dispose of almost any commodity and to ensure the best possible return on sales. Our extensive range of services include, inter alia; asset tracing, inventory listing and uplifting of any movable assets, regardless of size, transportation and warehousing, professional valuations and disposals, whether by private treaty or specialized auctions. Better defined as one-stop solution that continuously exceeds expectations; by utilizing expertise to unlock maximum value.</p>

               <p className = 'indent-7 pt-4'>Marketing items cycled through the auction framework include: Home Auctions, Machinery Auctions, Office and Bank Buildings, Electronics, Automobiles, Commercial and Residential Real Estate, Fine Art, License Plates and more which are provided for individuals, corporations, estates, attorneys, financial institutions and government. We bring our fully automated auction business to your location to convert your merchandise into cash and turn your auction into a worldwide event. We provide a technology to value your asset, create a marketing plan, conduct a sale or auction, handle all the details, and advise on the best exit strategy to the fullest of our effort to maximize your confidence in realizing your auction needs.</p>

               <h2 className = 'text-lg text-primary-100 font-semibold mt-5'>COMPANY PROFILE</h2>

               <p className = 'indent-7 pt-1'>The Company is joining the business market as of July 2023. The company has extensive experience in ICT business in Ethiopia, including: consulting for IT infrastructure development, providing epoch-making and best-fit system solutions and consulting on the provision of business technology bridges. Auction Ethiopia is administered by qualified experts (in business and IT) in Ethiopia. Academic readiness (PhD holders or specialist in the domain) and experience of staff in the company exceeds the expectation of business domain. Our people know the auction business, and we have managed some of the most complex and sensitive transactions imaginable.</p>
            </div>
         </div>

         <div className = 'pt-7 px-3 text-gray-500 pb-5 sm:pb-9'>
            <p className = 'pt-2'><span className = 'text-primary-100 font-semibold'>Employees: </span>Even though we are at the verge of joining the business, we have about 15 permanent employees with different areas of expertise. Among these: Software programmers, Finance manager, Marketing experts, and other operational positions.</p>

            <p className = 'pt-3'><span className = 'text-primary-100 font-semibold'>Revenues: </span>Our Auction platform serve both sellers and buyers. Hence, we generate revenue from service fee, registration fee, platform subscription fee. Accordingly, our estimated annual income is three billion birr. We will strengthen our competitiveness through new initiatives in an increasingly competitive and complex market so as to generate more income.</p>
         </div>

         <div className = 'w-full flex justify-center pb-4 sm:pb-8'>
            <p className = 'border-t border-stone-500 text-center w-[50%]'></p>
         </div>
      </div>
   )
};

export default AboutUs;