import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadCategories, selectCategory } from '../../features/categorySlice';
import { resetMinMaxPageLimit, setCurrentPage } from '../../features/paginationSlice';
import { toggleViewBy } from '../../features/viewControlsSlice';
import { logo_blur } from '../../assets';
import AuthService from '../../Services/AuthService';


const CategoryTabs = () => {

   const location = useLocation();
   const dispatch = useDispatch();
   const selectedCat = AuthService.getSelectedCategory();
   
   const { categories } = useSelector(({ category }) => category);
   const { language } = useSelector(({ language }) => language);
   const regex = /\/auction\/\d+/

   const handleSelect = id => {
      dispatch(setCurrentPage({num: 1}))
      dispatch(selectCategory({ id }))
      dispatch(toggleViewBy('all'))
      dispatch(resetMinMaxPageLimit())
   }

   const getAuctionsCount = count => {
      if(count){
         if(count > 99){
         return '99+'
         }
         return count
      }
      return null
   }
   
   useEffect(() => {
      if(!categories.length) dispatch(loadCategories())
      // eslint-disable-next-line
   }, [])

   useEffect(() => {
      const path = location.pathname.split('/')
      if(path[1] === 'category'){
         handleSelect(Number(path[3]))
      }
      // eslint-disable-next-line
   }, [location.pathname, categories])

   return (

      <div className = { `w-full grid grid-flow-col auto-cols-[minmax(0,_2fr)] gap-[2px] bg-white pl-1 pr-1` }>
         {categories.length ? 
            categories.map((category, index) => (
               <div key = { index } onClick = {() => handleSelect(category.id)} className = 'relative'>
                  <NavLink 
                     to = {`/category/auctions/${category.id}`}
                     className = {({isActive}) => (isActive || (regex.test(location.pathname) && category?.id === selectedCat?.id) ? 
                     'bg-sky-100 text-gray-800 border border-t-sky-600 border-b-sky-100 text-xs md:text-sm md:pb-1 cursor-pointer text-center items-center flex flex-col flex-1' : 
                     ' border border-b-sky-600 border-t-slate-300 bg-gradient-to-b from-gray-100 to-gray-400 text-white shadow hover:bg-gradient-to-b hover:from-gray-200 hover:to-gray-400 text-xs md:text-sm md:pb-1 cursor-pointer text-center items-center flex flex-col flex-1')}
                  >
                     <div className = 'w-full pt-1'>
                        
                        <div className = 'flex justify-center h-9'>
                           <img src = { category.image ? category.image : logo_blur } alt = { category.name } className = 'object-cover' />
                        </div>

                        <div className = {`${!category.no_of_auction ? 'invisible' : '' } w-6 pt-1 absolute right-1 top-0`}>
                           <div className = 'rounded-full flex justify-center items-center text-center h-5 w-5 text-[11px] bg-orange-400 border-2 border-orange-400 text-white'>
                              { getAuctionsCount(category.no_of_auction) }
                           </div>
                        </div>

                     </div>

                     <div className = 'w-full border-none whitespace-nowrap'>
                        { language === 'Amharic' ? category.name_am : language === 'Afaan-Oromoo' ? category.name_or : category.name }
                     </div>
                  </NavLink>
               </div>
            ))
            :
            null
         }
      </div>
   )
};

export default CategoryTabs;