import { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../features/languageSlice';
import { setLoginError, toggleChangePasswordModal } from '../../features/authSlice';
import { markNotificationAsSeen, toggle_notification_modal } from '../../features/chatSlice';
import { MdDeleteOutline, MdLogout, MdGavel, MdOutlineFileCopy, MdOutlineClose, MdArrowDropDown, MdSearch} from 'react-icons/md';
import { BsChat, BsTag, BsKey, BsCurrencyDollar, BsGlobe2, BsBell, BsEye, BsDot } from 'react-icons/bs';
import { SlUser } from 'react-icons/sl';
import { main_logo, user_icon, winner_crown } from '../../assets';
import { setQuery } from '../../features/auctionsSlice';
import Sidebar from '../sideBar/Sidebar';
import AuthService from '../../Services/AuthService';
import translate from '../../Services/translate';
import Loading from './Loading';
// import { GiveawayCountdown } from '../../utils';


const Header4 = ({ playNotificationSound }) => {

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const location = useLocation();

   const authToken = AuthService.getToken();
   const search_input = useRef(null)

   const { language } = useSelector(({ language }) => language);
   const { myProfile, login_error } = useSelector(({ auth }) => auth);
   const { new_notification_count, notifications, latest_notification } = useSelector(({ chat }) => chat);
   const { search_loading, isLive } = useSelector(({ auction }) => auction);

   const [showSearchBar, setShowSearchBar] = useState(false);
   const [searchQuery, setSearchQuery] = useState('');
   const [showCongrats, setShowCongrats] = useState(false);
   const [winnerNotification, setWinnerNotification] = useState([])

   const headerMenu = [
      { id: 142, link: '/home' },
      { id: 143, link: '/aboutUs' },
      { id: 127, link: '/faq' },
      { id: 337, link: '/subscription' },
      { id: 339, link: '/tutorials' }
   ]

   const handleSearch = val => dispatch(setQuery(val))

   const debounce = (cb) => {
      let timer;
      return function (...args) {
         const context = this
         if(timer) clearTimeout(timer)
         timer = setTimeout(() => {
            timer = null
            cb.apply(context, args)
         }, 500)
      }
   }

   // eslint-disable-next-line
   const handleQuery = useCallback(debounce((val) => handleSearch(val)), [])
  
   const closeWinnerNotification = (id) => {
      dispatch(markNotificationAsSeen({ notification_id: id }))
   }

   useEffect(() => {
      if(showSearchBar) search_input.current.focus();
      // eslint-disable-next-line
   }, [showSearchBar])

   useEffect(() => {
      if(notifications.length){
         const wnot = notifications.filter(n => n.message.data.msg_type === 'auction_won' && !n.seen )
         setWinnerNotification(wnot)
         if(wnot.length) setShowCongrats(true)
      }
      if(new_notification_count && latest_notification){
         playNotificationSound('/Notification.mp3')
      }
      // eslint-disable-next-line
   }, [notifications]);

   const lang = [
      {val: 'English', prev: 'English'},
      {val: 'Amharic', prev: 'አማርኛ'},
      {val: 'Afaan-Oromoo', prev: 'Afaan-Oromoo'},
   ]


   return (
      <div className = 'w-full h-[104px] sticky top-0 right-0 left-0 z-10 bg-white'>
         <div className = 'flex w-full h-full relative'>
            <div className = 'w-[37%] md:w-[24%] lg:w-[18%] bg-white h-full flex items-center justify-center pl-2'>
               <img src = { main_logo } alt = 'Auction Ethiopia S.C' onClick = { () => navigate('/home') } className = 'h-10 sm:h-14 md:h-14'/>
            </div>

            {/* <div className = 'w-[45%] h-full hidden md:block md:pl-9 lg:pl-14'> */}
            {/* <div className = 'w-[45%] h-full hidden lg:block pl-14'> */}
            <div className = 'w-[51%] h-full hidden lg:block pl-10'>
               {/* <div className = 'h-full w-full flex items-center justify-start md:gap-5 lg:gap-12 whitespace-nowrap'> */}
               <div className = 'h-full w-full flex items-center justify-start gap-[5%] whitespace-nowrap'>
                  {
                     headerMenu.map((menu, i) => (
                        <div key = { i } className = {`${language === 'Amharic' ? 'text-gray-500' : 'text-gray-700'} text-center uppercase`} title = { menu.link === '/faq' && language === 'Afaan-Oromoo' ? 'Gaaffilee Yeeroo Baayyee Gaafataman' : null}>
                              <NavLink
                                 className = {({ isActive }) => ( isActive ? 
                                    'text-blue-600 text-sm md:text-base border-b-2 border-blue-600 md:pb-[6px]' : 
                                    'py-1 text-sm md:text-base text-center hover:text-gray-800 md:pb-[6px]'
                                 )}
                                 to = { menu.link}
                              >
                                 { translate(menu.id) }
                              </NavLink>
                        </div>
                     ))
                  }
               </div>
            </div>
            
            {/* <div className = 'w-[65%] md:w-[41%] h-full flex items-center justify-between sm:justify-end sm:gap-2 md:gap-4 relative'> */}
            {/* <div className = 'w-[63%] md:w-[41%] h-full flex items-center justify-end gap-3 md:gap-4 relative'> */}
            <div className = 'w-[63%] md:w-[76%] lg:w-[31%] h-full flex items-center justify-end gap-3 md:gap-4 relative'>
               <div className = 'hidden md:block mr-6'>
                  <MdSearch 
                     // className = {`h-full text-gray-500 w-5 md:w-7  cursor-pointer ml-0 sm:ml-3 md:mr-6 
                     className = {`h-full text-gray-500 w-5 md:w-7  cursor-pointer
                     ${location.pathname.split('/')[2] === 'auctions' ? 'visible' : 'invisible'}`} 
                     onClick = {() => { 
                        setShowSearchBar(true)
                        setSearchQuery('')
                        dispatch(setQuery('')) 
                     }}
                  />
               </div>

               <div className = 'hidden md:block'>
                  <div className = 'flex justify-center items-center'>
                     <BsGlobe2 className = 'h-5 w-5 text-sky-400'/>

                     <div className="dropdown relative inline-block px-2 w-28 bg-transparent" >
                        <button className="bg-transparent cursor-pointer text-sky-600 text-sm hover:text-sky-700">
                        {lang.filter(l => l.val === language)[0].prev}
                        </button>
                        <ul className="dropdown-menu absolute top-[100%] left-0 cursor-pointer min-w-full shadow-md shadow-stone-400 bg-black/50 text-white text-sm">
                           {lang.map((lan, i) => (
                              <li className = 'hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center pl-2' 
                              key = { i } 
                              onClick = {() => {
                                 dispatch(setLanguage({ lang: lan.val }));
                                 login_error
                                    ? !['Please fill out all fields', 'እባክዎን ሁሉንም ሳጥኖች ይሙሉ', 'Mee saanduqa hunda guutaa'].includes(login_error)
                                       ? dispatch(setLoginError({ msg: translate(144) }))
                                       : dispatch(setLoginError({ msg: translate(77) }))
                                    : dispatch(setLoginError({ msg: '' }))

                              }}>
                                 {lan.prev}
                              </li>
                           ))}
                        </ul>
                     </div>

                  </div>
               </div>
               
               <div className = 'sm:pl-2'>
                  {
                     !authToken
                     ?
                     <div className = 'flex gap-2 justify-end'>
                        <div className = 'sm:pr-4'>
                           <button 
                              onClick = { () => navigate('/register') } 
                              className = 'text-gray-700 rounded-2xl py-1 border border-gray-700 px-4 md:px-6 text-[10px] sm:text-sm hover:text-sky-600 hover:bg-white hover:border-sky-600 uppercase'
                           >
                              { translate(95) }
                           </button>
                        </div>
                        <div className = 'sm:pr-3'>
                           <button 
                              onClick = { () => navigate('/login') } 
                              // className = 'text-gray-700 rounded-2xl py-1 border border-gray-700 sm:px-4 text-[10px] sm:text-sm w-20 whitespace-nowrap md:w-28 hover:text-sky-600 hover:bg-white hover:border-sky-600 uppercase'
                              className = 'text-gray-700 rounded-2xl py-1 border border-gray-700 px-4 md:px-6 text-[10px] sm:text-sm whitespace-nowrap hover:text-sky-600 hover:bg-white hover:border-sky-600 uppercase'
                           >
                              { translate(86) }
                           </button>
                        </div>
                     </div>
                     :
                     <div className = 'flex gap-5'>
                        <div 
                           onClick = { () => dispatch(toggle_notification_modal({ value: true })) } 
                           className = { `relative pr-5 sm:pr-0 md:w-8 md:h-8 cursor-pointer md:mr-1 lg:mr-3 ${new_notification_count ? 'has-notification' : ''}` }
                        >
                           <BsBell className = 'h-9 w-9 text-gray-500' />
                           {
                              new_notification_count
                              ?
                              <div className = 'absolute top-0 right-0 h-[18px] w-[18px] mt-[4px] inset-x-[18px] border-2 border-red-500 rounded-full bg-red-500 text-center flex justify-center items-center'>
                                 {
                                    new_notification_count > 99 ?
                                    <div className = 'text-[0.6rem] text-white'>99<sup>+</sup></div>
                                    :
                                    <div className = 'text-[0.6rem] text-white'>{ new_notification_count }</div>
                                 }
                              </div>
                              :
                              null
                           }
                        </div>

                        <div className = 'hidden lg:block'>
                           <div className = 'relative group'>
                              <div className = 'justify-end gap-4 flex cursor-pointer w-auto'>

                                 <div className = ' w-9 h-9 rounded-full mr-'>
                                    <img className = 'h-full w-full object-cover rounded-full border border-gray-400' 
                                       src = { myProfile?.user_profile?.image_url ? 
                                       `${myProfile.user_profile.image_url}` :
                                       user_icon } alt = 'user'
                                       onClick = { () => navigate('/myAccount') }
                                    />
                                 </div>

                                 <div className = 'text-sky-800 w-[165px] text-center flex justify-between gap-1 pr-1'>
                                    <p className = 'whitespace-nowrap truncate text-sm pt-2' > { translate(145) } { authToken.user ? authToken.user : '' } </p>
                                    <div className = 'pr-3 pt-[2px]'><MdArrowDropDown className = 'invisible md:visible text-3xl'/> </div>
                                 </div>

                                 <div className = 'top-8 absolute z-30 hidden group-hover:block invisible md:visible pt-2'>
                                    <div className = 'bg-black/50 backdrop-blur-sm rounded-b text-xs md:text-sm shadow-md shadow-stone-400 mr-2'>
                                       <div className = 'grid grid-cols-1 gap-0 whitespace-nowrap text-white min-w-[210px]'>
                                          <div 
                                             onClick = { () => navigate('/myAccount') } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center'>
                                             <div className = 'mr-1'><SlUser className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(146) }</p> 
                                          </div>
                                          <div 
                                             onClick = { () => navigate('/my-watchlist') } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center'>
                                             <div className = 'mr-1'><BsEye className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(147) }</p> 
                                          </div>
                                          <div 
                                             onClick = { () => navigate('/my-bids') } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center'>
                                             <div className = 'mr-1'><MdGavel className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(129) }</p> 
                                          </div>
                                          <div 
                                             onClick = { () => navigate('/my-purchases') } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center'>
                                             <div className = 'mr-1'><BsTag className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(130) }</p> 
                                          </div>
                                          <div 
                                             onClick = { () => navigate('/my-balance') } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center'>
                                             <div className = 'mr-1'><BsCurrencyDollar className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(131) }</p> 
                                          </div>
                                          <div 
                                             onClick = { () => navigate('/my-documents') } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center'>
                                             <div className = 'mr-1'><MdOutlineFileCopy className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(132) }</p> 
                                          </div>
                                          <div 
                                             onClick = { () => dispatch(toggleChangePasswordModal({ value: true })) } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center'>
                                             <div className = 'mr-1'><BsKey className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(148) }</p>
                                          </div>
                                          <div 
                                             onClick = { () => navigate('/chatting') } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 flex items-center'>
                                             <div className = 'mr-1'><BsChat className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm truncate'>{ translate(149) }</p>
                                          </div>
                                          <div 
                                             onClick = { () => navigate('/delete-account') } 
                                             className = 'px-2 hover:bg-gray-100 py-2 border-b border-gray-400 font-semibold text-red-300 hover:text-red-600 flex items-center' >
                                             <div className = 'mr-1'><MdDeleteOutline className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(76) }</p>
                                          </div>
                                          <div 
                                             onClick = { () => navigate('/logout') } 
                                             className = 'px-2 hover:bg-gray-100 hover:text-sky-600 py-2 border-b border-gray-400 font-semibold text-sky-200 flex items-center'>
                                             <div className = 'mr-1'><MdLogout className = 'text-base'/></div>
                                             <p className = 'pl-2 text-sm'>{ translate(150) }</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  }
               </div>

               <div className = 'absolute bottom-1 h-[30%] w-full flex justify-end items-end'>
                  <div className = {`${!showSearchBar ? 'hidden' : 'block'} flex-grow px-3 w-1/2`}>
                     <div className = {`relative text-right`}>
                        {
                           search_loading
                           ?
                           <Loading loading = { search_loading } loadingStyle = 'text-sky-600 w-4 h-4 absolute right-[3%] md:right-[51%] top-[6px]'/>
                           :
                           <MdOutlineClose 
                              className = 'text-gray-600 hover:text-red-500 absolute right-[3%] md:right-[51%] top-[6px] cursor-pointer'
                              onClick = { () => {
                                 setShowSearchBar(false)
                                 setSearchQuery(null)
                                 dispatch(setQuery(''))
                              }} 
                           />
                        }
                     </div>
                     <label>
                        <input 
                           id = 'search_query'
                           type = 'text' 
                           value = { searchQuery } 
                           placeholder = 'type here to search' 
                           className = {`rounded-xl border border-gray-400 md:rounded-full w-full md:w-1/2 bg-white h-7 pl-3 pr-2 text-gray-700 focus:outline-none text-xs md:text-sm`} 
                           onChange = { (e) => {
                              setSearchQuery(e.target.value)
                              handleQuery(e.target.value) 
                           }} 
                           autoFocus 
                           ref = { search_input}
                        />
                     </label>
                  </div>

                  <div className = 'ml-0 block md:hidden pb-1 pr-3'>
                     <MdSearch 
                        className = {`h-full text-gray-500 w-5 cursor-pointer
                        ${location.pathname.split('/')[2] === 'auctions' ? 'visible' : 'invisible'}`} 
                        onClick = {() => { 
                           setShowSearchBar(true)
                           setSearchQuery('')
                           dispatch(setQuery('')) 
                        }}
                     />
                  </div>

               </div>

               <div className = 'block lg:hidden pr-2'>
                  <Sidebar />
               </div>

            </div>

            {showCongrats && authToken ?
               <div className = 'absolute top-1 right-0 left-0 sm:left-[30%] md:right-[25%] md:left-[52%] text-center px-2 sm:px-0 z-20'>
                  {
                     winnerNotification.length ?

                     // winnerNotification.map(winner => (
                     //    <div 
                     //       key = { winner.id } 
                     //       className = 'congrats w-full sm:w-[400px] rounded-lg border border-sky-600 bg-lime-600 py-1 text-white flex gap-4 justify-around cursor-pointer p-3 relative pt-2' 
                     //       onClick={() => {
                     //          closeWinnerNotification(winner.id)
                     //          navigate(`/auction/${winner.message.data.object_id}`)
                     //       }}
                     //    >
                     //       <div className = 'flex flex-col'>
                     //          <div className = 'flex gap-4'>
                     //             <img src = { winner.message.notification.image } alt = '' align = 'left' className = 'h-12 pt-1 pr-3 object-cover' />
                     //             <p className = 'text-lg'>{ translate(151) }</p>
                     //          </div>
                     //          <div className = 'pl-20'>
                     //             <p className = 'text-sm'>{ translate(152) }</p>
                     //          </div>
                     //       </div>

                     //       <div className = 'mr-3'>
                     //          <img src = { winner_crown } alt = '' className = 'h-14' />
                     //       </div>
                     //       <MdOutlineClose 
                     //          className = 'absolute top-1 right-1 text-white text-lg'
                     //          onClick = {e => { 
                     //             e.stopPropagation()
                     //             closeWinnerNotification(winner.id)
                     //          }} 
                     //       />
                     //    </div>
                     // ))

                     <div 
                        // key = { winner.id } 
                        className = 'congrats w-full sm:w-[400px] rounded-lg border border-sky-600 bg-lime-600 py-1 text-white flex gap-4 justify-around cursor-pointer p-3 relative pt-2' 
                        onClick={() => {
                           closeWinnerNotification(winnerNotification[0].id)
                           navigate(`/auction/${winnerNotification[0].message.data.object_id}`)
                        }}
                     >
                        <div className = 'flex flex-col'>
                           <div className = 'flex gap-4'>
                              <img src = { winnerNotification[0].message.notification.image } alt = '' align = 'left' className = 'h-12 pt-1 pr-3 object-cover' />
                              <p className = 'text-lg'>{ translate(151) }</p>
                           </div>
                           <div className = 'pl-20'>
                              <p className = 'text-sm'>{ translate(152) }</p>
                           </div>
                        </div>

                        <div className = 'mr-3'>
                           <img src = { winner_crown } alt = '' className = 'h-14' />
                        </div>
                        <MdOutlineClose 
                           className = 'absolute top-1 right-1 text-white text-lg'
                           onClick = {e => { 
                              e.stopPropagation()
                              closeWinnerNotification(winnerNotification[0].id)
                           }} 
                        />
                     </div>
                     :
                     null
                  }
               </div> : 
               null
            }

            <div className = 'absolute -top-2 right-3 flex items-center'>
               {/* <GiveawayCountdown /> */}
               <BsDot className = {`${isLive ? 'text-green-600  live-indicator' : 'text-red-400'} text-5xl sm:text-5xl`} />
               <Loading loading = {!isLive} min loadingStyle = 'text-gray-800 w-4 h-4 sm:-ml-2' />
               <div className = {`${isLive ? 'text-green-600 -ml-3' : 'text-gray-800 ml-2 pb-1'} text-sm sm:text-lg font-Roboto`}>{isLive ? 'Live' : 'connecting...'}</div>
            </div>
         </div>
      </div>
   )
}

export default Header4;
