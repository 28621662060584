import { useDispatch, useSelector } from 'react-redux';
import { toggleViewType } from '../../features/viewControlsSlice';
import { BsGrid3X2, BsListTask } from 'react-icons/bs';
import translate from '../../Services/translate';


const ViewTypeToggle = () => {

  const dispatch = useDispatch();

  // eslint-disable-next-line
  const { language } = useSelector(({ language }) => language);
  const { viewType } = useSelector(({ viewControls }) => viewControls);

  return (
    <div className = 'hidden lg:block md:flex justify-start pl-5'>
      <div className = 'items-center flex gap-1 pt-[13px]'>
        {/* <p className = 'text-gray-700 pr-1 text-sm'>{ translate(308) } </p> */}
        <button
          onClick = { () => dispatch(toggleViewType('list')) }
          className = { `px-1 border rounded   
            ${viewType === 'list' ? 
            'bg-gray-500 text-white border-gray-500' : 
            'border-gray-400 hover:border-sky-600 hover:bg-white hover:text-sky-600 bg-gray-100'}` }
          title = { translate(313) }
        >
          <BsListTask className = 'h-[27px] w-[28px]' />
        </button>
        <button
          onClick = { () => dispatch(toggleViewType('grid')) }
          className = { `px-1 border rounded  
            ${viewType === 'grid' ? 
            'bg-gray-500 text-white border-gray-500' : 
            'border-gray-400 hover:border-sky-600 hover:bg-white hover:text-sky-600 bg-gray-100'}` }
          title = { translate(314) }
        >
          <BsGrid3X2 className = 'h-[27px] w-[28px] ' />
        </button>
      </div>
    </div>
  );
};

export default ViewTypeToggle;