import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CountdownTimer, getCharLength, opt } from '../../utils';
import { selectCategory } from '../../features/categorySlice';
import { image_placeholder, sealed_icon, zig_chereta } from '../../assets';
import dayjs from 'dayjs';
import translate from '../../Services/translate';


const PurchasesGrid = () => {

   const navigate = useNavigate();
   const dispatch = useDispatch();
   
   const { language } = useSelector(({ language }) => language);
   const { purchases } = useSelector(({ purchase }) => purchase);


   return (
      <div className = 'pt-2'>
         {purchases.length ?
            <div className = 'sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-2 md:px-0'>
               {
                  purchases.map((purchase, i) => (
                  <div key = { i }  className = 'text-center mt-2 md:mt-0 rounded-lg shadow-lg shadow-stone-400 border border-stone-400 sm:hover:scale-105 duration-200'>
                     <div className = 'bg-white text-gray-600 h-72 flex-col flex justify-center pt-2 rounded-lg'>
                        <div className = 'px-2 flex relative h-[32%]'>
                           <div className = 'w-28 h-[67.2px] flex flex-col bg-black/50'>
                              <img
                                 className = 'w-full h-full object-contain rounded cursor-pointer'
                                 src = { purchase?.product?.thumbnail ? purchase.product.thumbnail : image_placeholder }
                                 alt = { purchase.product.name }
                                 onClick = {() => {
                                    dispatch(selectCategory({ id: purchase.product.category }))
                                    navigate(`/auction/${purchase.id}`)
                                 }}
                              />
                           </div>

                           <div className = 'w-3/5 flex flex-col gap-1 justify-between pb-2 pl-4'>
                              <p
                                 className = 'text-base text-left hover:text-sky-600 text-black font-Roboto flex-none cursor-pointer'
                                 onClick = {() => { 
                                    dispatch(selectCategory({ id: purchase.product.category }))
                                    navigate(`/auction/${purchase.id}`) 
                                 }}
                              >
                                 { purchase.product.name }
                              </p>
                           
                              <p className = 'text-xs text-start overflow-y-auto flex-grow summary'>
                                 {purchase?.product?.summary ?
                                    <>
                                       <span>{purchase.product.summary.slice(0, getCharLength())}</span>
                                       <span 
                                          onClick = { () => { dispatch(selectCategory({ id: purchase.product.category })); navigate(`/auction/${purchase.id}`) }} 
                                          className = ' text-sky-600 cursor-pointer hover:text-sky-700'
                                       >
                                          { translate(50) }
                                       </span>
                                    </> 
                                    :
                                    <span>---</span>
                                 }
                              </p>
                           </div>
                        </div>

                        <div className = 'h-[9%] flex justify-evenly items-center pb-1 border-b border-gray-400'>
                           <div className = 'text-xs'>{ translate(17) } &nbsp; - &nbsp; <span className = 'text-base font-semibold'> { purchase.no_of_bids }</span></div>
                           <div className = 'text-sky-600'>|</div>
                           <div className = 'text-xs'>{ translate(18) } &nbsp; - &nbsp; <span className = 'text-base font-semibold'> { purchase.lot_number }</span></div>
                        </div>

                        {purchase.is_closed ?
                           // <div className = 'border-b border-gray-400 flex h-[30%] items-center justify-center font-semibold gap-3'>
                           //    <img src = { sealed_icon } alt = '' className = 'h-10 w-10' />
                           //    <div className = 'text-red-400 text-lg'>{ translate(14) }</div>
                           // </div>

                           <div className = 'border-b border-gray-400 flex flex-col h-[30%] items-center justify-between'>
                              <div className = 'flex items-center justify-center font-semibold gap-3'>
                                 <img src = { language === 'Amharic' ? zig_chereta : language === 'Afaan-Oromoo' ? sealed_icon : sealed_icon } alt = '' className = 'h-10 w-10' />
                                 <div className = 'text-red-400 text-lg'>{translate(14)}</div>
                              </div>

                              <div className = 'flex flex-col justify-between border-t border-gray-400 w-full'>
                                 <p className = 'text-xs text-left pl-2 truncate'>{ translate(19) }</p>
                                 <p className = 'text-sm'>
                                    <span className = 'text-lg font-semibold font-Roboto'>{ purchase.initial_price.toLocaleString('en', opt) }</span>
                                    { translate(4) }
                                 </p>
                              </div>
                           </div>
                           :
                           <>
                              <div className = 'border-b border-gray-400 flex h-[15%]'>
                                 <div className = 'border-r border-gray-400 flex flex-col justify-between w-[60%] bg-fourth-50'>
                                    <p className = 'text-xs text-left text-white pl-2'>{ translate(3) }</p>
                                    <p className = 'text-sm text-white'>
                                       <span className = 'text-lg font-semibold'> { purchase.current_price.toLocaleString('en', opt) }</span> 
                                       { translate(4) }
                                    </p>
                                 </div>
                                 <div className = 'flex flex-col justify-between w-[40%]'>
                                    <p className = 'text-xs text-left pl-2'>{ translate(15) }</p>
                                    <p className = 'text-sm'>
                                       <span className = 'text-lg font-semibold'> { purchase.min_increment.toLocaleString('en', opt) }</span>
                                       { translate(4) }
                                    </p>
                                 </div>
                              </div>

                              <div className = 'border-b border-gray-400 flex h-[15%]'>
                                 <div className = 'flex flex-col justify-between border-r border-gray-400 w-[60%]'>
                                    <p className = 'text-xs text-left pl-2'>{ translate(19) }</p>
                                    <p className = 'text-sm'>
                                       <span className = 'text-lg font-semibold'> { purchase.initial_price.toLocaleString('en', opt) }</span> 
                                       { translate(4) }
                                    </p>
                                 </div>
                                 <div className = 'flex flex-col justify-between w-[40%]'>
                                    <p className = 'text-xs text-left pl-2 truncate'>{ translate(16) }</p>
                                    <p className = 'text-sm truncate px-1'>
                                       <span className = {`text-lg ${language === 'Amharic' && !purchase.max_increment ? 'font-normal' : 'font-semibold font-Roboto'}`}> 
                                          { purchase.max_increment ? purchase.max_increment.toLocaleString('en', opt) : translate(81) }
                                       </span> 
                                       { purchase.max_increment ? translate(4) : null }
                                    </p>
                                 </div>
                              </div>
                           </>
                        }

                        <div className = 'border-b border-gray-400 flex h-[15%]'>
                           <div className = 'border-r border-gray-400 flex flex-col justify-between w-[47%]'>
                              <p className = 'text-xs text-left pl-2'>{ translate(20) }</p>
                              <CountdownTimer
                                 timestampMs = { new Date(purchase.end_time).getTime() }
                                 view = 'grid'
                              />
                           </div>
                           <div className = 'flex flex-col justify-between w-[53%]'>
                              <p className = 'text-xs text-left pl-2'>{ translate(25) }</p>
                              <p className = 'text-sm font-semibold '>{ dayjs(new Date(purchase.end_time).toISOString()).format('h:mm a, MMM D, YYYY') }</p>
                           </div>
                        </div>

                        <div className = 'flex h-[14%]'>
                           <div className = 'flex justify-center items-center border-r border-gray-400 p-1 w-full'>
                              <button
                                 className = 'bg-primary-100 w-full h-full text-white text-xs hover:bg-lime-600'
                                 onClick = {() => { 
                                    dispatch(selectCategory({ id: purchase.product.category })) 
                                    navigate(`/auction/${purchase.id}`)
                                 }}
                              >
                                 { translate(37) }
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  ))
               }
            </div>
            :
            <div className = 'w-full flex justify-center pt-12 text-sky-600 font-semibold pb-20 md:pb-0'>{ translate(269) }</div> 
         }
      </div>
   )
}

export default PurchasesGrid;

