
function toWords(n) {
   if (n < 0) {
     return false;
   }
 
   let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
   let double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
   let below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
 
   if (n === 0) {
     return 'Zero';
   }
 
   function translate(n) {
      let word = '';
      if (n < 10) {
         word = single_digit[n] + ' ';
      } else if (n < 20) {
         word = double_digit[n - 10] + ' ';
      } else if (n < 100) {
         let rem = translate(n % 10);
         word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;
      } else if (n < 1000) {
         word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100);
      } else if (n < 1000000) {
         word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000);
      } else if (n < 1000000000) {
         word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000);
      } else if (n < 1000000000000) {
         word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000);
      } else if (n < 1000000000000000) {
         word = translate(parseInt(n / 1000000000000)).trim() + ' Trillion ' + translate(n % 1000000000000);
      }
   
      if (n >= 1000000000000000) {
         return 'too big number';
      }
 
     return word;
   }

   function isInteger(n) {
      return n % 1 === 0;
   }

   let result = '';
   if (isInteger(n)) {
      result = translate(n);
   } else {
      const decimalWords = translate(parseInt((n % 1).toFixed(2).slice(2)));
      result = translate(Math.trunc(n)) + ' and ' + decimalWords + ' cents';
   }
 
   return result.trim();
 }

 export default toWords;