import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowVideoModal } from '../../features/auctionsSlice';
import Modal from './Modal';


const ThumbnailGrid = () => {

   const dispatch = useDispatch();

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { showVideoModal, how_to_use_videos } = useSelector(({ auction }) => auction);
   const [modalOpen, setModalOpen] = useState(false);
   const [selectedVideo, setSelectedVideo] = useState('');

   const openModal = (videoId) => {
      setSelectedVideo(videoId);
      setModalOpen(true);
   };

   const closeModal = () => {
      setModalOpen(false);
      setSelectedVideo('');
   };

   return (
      <Modal visible = { showVideoModal } customWidth = 'w-[94%] sm:w-[70%] md:w-[73.1%] h-[90.4%] overflow-y-auto p-' bg = 'bg-black' className = 'relative backdrop:to-black'>
         <div className = 'bg-gradient-to-r from-sky-700 via-lime-700 to-lime-50 py-1 flex justify-between items-center px-3'>
            <div className ='text-white'>How To Use Videos</div>
            <div className = 'text-gray-700 text-xl hover:text-red-600 cursor-pointer w-12 text-right' onClick = {() => dispatch(setShowVideoModal({ value: false }))}>&#10006;</div>
         </div>

         <div className = 'thumbnail-grid px-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-x-7 pt-6'>
            {how_to_use_videos.map((video, index) => {
               let videoId = video.link.slice(17,28)
               return(
                  <div
                     key = { index }
                     className = 'cursor-pointer hover:scale-105 duration-200 w-auto'
                     onClick = {() => openModal(videoId)}
                  >
                     <img
                        src = {`https://img.youtube.com/vi/${videoId}/0.jpg`}
                        alt = { video.description }
                     />
                     <p className = 'text-white text-sm text-center pt-4'>
                        { video.description } <br/>
                        { video.description_am }
                     </p>
                  </div>
               )
            })}
         </div>

         {modalOpen && (
            <div className = 'modal absolute top-0 right-0 h-[50vh]'>
               <div className = 'close-icon flex items-center justify-center bg-black rounded-full h-7 w-7' onClick = { closeModal }>
                  <div >&#10006;</div>
               </div>
               <div className = 'video-container h-full w-[100%] absolute'>
                  <iframe
                  className = 'iframe-embed'
                  title = 'YouTube Video'
                  height = '100%'
                  src = {`https://www.youtube.com/embed/${selectedVideo}?autoplay=1&controls=1&rel=0`}
                  allowFullScreen
                  ></iframe>
               </div>
            </div>
         )}
      </Modal>
   );
};

export default ThumbnailGrid;
