import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/authSlice';
import categoryReducer from '../features/categorySlice';
import auctionReducer from '../features/auctionsSlice';
import watchListReducer from '../features/watchListSlice';
import languageReducer from '../features/languageSlice';
import viewControlsReducer from '../features/viewControlsSlice';
import paginationReducer from '../features/paginationSlice';
import bidReducer from '../features/bidSlice';
import newsReducer from '../features/newsSlice';
import purchaseReducer from '../features/purchaseSlice';
import documentReducer from '../features/documentsSlice';
import chatReducer from '../features/chatSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        category: categoryReducer,
        auction: auctionReducer,
        watchList: watchListReducer,
        language: languageReducer,
        viewControls: viewControlsReducer,
        pagination: paginationReducer,
        bid: bidReducer,
        news: newsReducer,
        purchase: purchaseReducer,
        document: documentReducer,
        chat: chatReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export default store;