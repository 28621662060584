import axios from './interceptor'
import { base_url } from './apiConstants'

export default class myModel {

  static base_url = base_url;

  static create(modelName, id, data, filter = null) {
    let url = modelName;
    if (id) url += `/${id}`
    if (filter) url += '?' + filter;

    return new Promise(function (resolve, reject) {
      axios.post(url, data)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  static find(modelName, id, filter) {
    let url = modelName;
    
    if (id) url += `/${id}`

    if(filter){
      let qr = '';
      Object.keys(filter).forEach(key => {
        if(qr === ''){
          qr += `${key}=${filter[key]}`
        }else{
          qr += `&${key}=${filter[key]}`
        }
      });
      url += '?' + qr;
    }

    return new Promise(function (resolve, reject) {
      axios.get(url)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  static update(modelName, id, data) {

    let url = modelName;
    if(id) url += '/' + id

    return new Promise(function (resolve, reject) {
      axios.patch(url, data)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  static upload(modelName, files ) {

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    let data = new FormData();
    for(let i = 0; i < Object.keys(files).length; i++){
      data.append(Object.keys(files)[i], Object.values(files)[i])
    }

    return new Promise(function (resolve, reject) {
      axios.post(modelName, data, config)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  static destroy(modelName, id) {

    let url = modelName;
    if(id) url += modelName + '/' + id;

    return new Promise(function (resolve, reject) {
      axios.post(url)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

}
