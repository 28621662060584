
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { opt } from '../../utils';
import translate from '../../Services/translate';
import { GiTrophyCup } from "react-icons/gi";


const BidderBar = forwardRef(({ idx, first_name, last_name, amount, monitor, isAnimating }, ref) => {

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const backgroundColor = isAnimating ? 'bg-green-500' : 'bg-lime-500';


   return ( 
      <div ref={ref} className = 'w-full'>
         <div className = {`bar ${monitor ? 'h-[30px] my-[0.6rem] lg:h-[70px] pr-1 sm:pr-5  sm:mx-[0.6rem]' : 'h-[35px] my-[0.3rem] pr-2 sm:pr-5 '} flex justify-between gap-2 rounded-md border border-sky-700 ${idx <= 2 ? 'font-semibold' : ''} ${backgroundColor}`}>

            <span className = {`pl-1 sm:pl-2 flex items-center text-white w-[10%] justify-center ${monitor ? 'text-base lg:text-3xl' : 'text-base'} ${idx <= 2 ? 'font-bold' : 'font-[300]'}`}>    {idx + 1} 
            </span>

            <div className={`flex justify-start w-[45%] text-white items-center h-full truncate font-Roboto ${monitor ?'text-base lg:text-3xl' : 'text-base'}`}>
               { first_name ? first_name : '' }  { last_name ? last_name : '' }
            </div>

            {idx === 0 ? 
               <div className = 'h-full flex items-center'>
                  <GiTrophyCup className = {`${monitor ? 'text-2xl sm:text-5xl' : 'text-2xl'} text-yellow-300 top-bider-cup`}/>
               </div> 
               : 
               null
            }
            <div className={`flex justify-end w-[40%] text-white items-center h-full ${monitor ?'text-base lg:text-3xl' : 'text-base'}`}> 
               { amount.toLocaleString('en', opt) } { translate(4) }
            </div>
         </div>
      </div>
   )
});

export default BidderBar;