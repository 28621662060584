import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../../features/categorySlice';
import { setCurrentPage } from '../../features/paginationSlice';
import { toggleMobViewByToggle, toggleSideCategoryBar, toggleViewBy } from '../../features/viewControlsSlice';
import { logo_blur } from '../../assets';

const SideCategoryBar = () => {

  const dispatch = useDispatch();

  const { showSideCategoryBar } = useSelector(({ viewControls }) => viewControls)
  const { categories } = useSelector(({ category }) => category);
  const { language } = useSelector(({ language }) => language);


  const handleSelect = id => {
    dispatch(toggleMobViewByToggle({ value: false }))
    dispatch(setCurrentPage({ num: 1 }))
    dispatch(selectCategory({ id }))
    dispatch(toggleViewBy('all'))
    dispatch(toggleSideCategoryBar({ value: false }))
  }
  
  const auctionCount = count => {
    if(count){
      if(count > 99){
        return '99+'
      }
      return count
    }
  }

  return (
    <div className = { `pt-4 bg-transparent duration-[350ms] w-40 flex justify-center fixed z-10 h-[100vh] top-0 ${showSideCategoryBar ? 'right-0' : 'right-[-100%]'}` }>
      <div className = 'w-[100%] grid grid-flow-row h-[82%]'>
        {
          categories.length
          ? 
          categories.map((category, index) => (
            <div key = { index } onClick = { () => handleSelect(category.id) } className = 'relative'>
              <NavLink 
                to = { `/category/auctions/${category.id}` }
                className = { ({ isActive }) => (isActive ? 
                'bg-white text-gray-800 border-t border-x border-sky-600 text-xs cursor-pointer text-center items-center flex flex-col flex-1 h-full' : 
                'border-t border-lime-500 bg-gradient-to-b from-gray-100 to-gray-400 text-white shadow hover:bg-gradient-to-b hover:from-gray-200 hover:to-gray-400 text-xs cursor-pointer text-center items-center flex flex-col flex-1 h-full') }>
  
                <div className = 'w-full flex justify-center pt-1'>
                  <div>
                    <img src = { category.image ? category.image : logo_blur } alt = { category.name } className = 'h-7' />
                  </div>
  
                  <div className = { `${!category.no_of_auction ? 'invisible' : ''} w-5 absolute right-1 top-1` }>
                    <div className = 'rounded-full flex justify-center items-center text-center h-4 w-4 bg-orange-400 border border-orange-400 text-white text-[10px]'>
                      { auctionCount(category.no_of_auction) }
                    </div>
                  </div>
                </div>
  
                <div className = 'w-full border-none whitespace-nowrap pt-1'>
                  { language === 'Amharic' ? category.name_am : language === 'Afaan-Oromoo' ? category.name_or : category.name }
                </div>
                
              </NavLink>
            </div>
          ))
          :
          null
        }
      </div>
    </div>
  )
}

export default SideCategoryBar;
