import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   currentPage: 1,
   mobPage: 1,
   limit: 12,
   displayPageNumbers: 5,
   minPageLimit: 0,
   maxPageLimit: 5,
   query: '',
}

const paginationSlice = createSlice({
   name: 'pagination',
   initialState,
   reducers: {
      setCurrentPage: (state, { payload }) => {
         state.currentPage = payload.num
         state.mobPage = payload.num
      },
      setMinMaxPageLimit: (state, { payload }) => {
         if(payload.e === 'prev'){
            state.minPageLimit = payload.min - state.displayPageNumbers
            state.maxPageLimit = payload.max - state.displayPageNumbers
         }
         else{
            state.minPageLimit = payload.min + state.displayPageNumbers
            state.maxPageLimit = payload.max + state.displayPageNumbers
         }
      },
      resetMinMaxPageLimit: (state) => {
         state.minPageLimit = 0
         state.maxPageLimit = 5
      }
   }
});

export const { setCurrentPage, setMinMaxPageLimit, resetMinMaxPageLimit } = paginationSlice.actions;
export default paginationSlice.reducer;