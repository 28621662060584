import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myModel from '../Services/api';

const initialState = {
   document_loading: false,
   document_loading2: false,
   document_status: '',
   documents: [],
   show_upload_document_modal: false,
}

export const getDocuments = createAsyncThunk(
   'document/getDocuments',
   async (arg, { rejectWithValue }) => {
      try {
         const response = await myModel.find('my-documents/')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const uploadDocuments = createAsyncThunk(
   'document/uploadDocuments',
   async (arg, { rejectWithValue }) => {
      try {
         const response = await myModel.upload('my-documents/upload', arg)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const documentsSlice = createSlice({
   name: 'document',
   initialState,
   reducers: {
      toggleUploadDocumentModal: (state, { payload: { value } }) => {
         state.show_upload_document_modal = value
      },
   },
   extraReducers: ({ addCase }) => {
      addCase(getDocuments.pending, (state) => {
         state.document_loading = true
      })
      addCase(getDocuments.fulfilled, (state, { payload }) => {
         state.documents = payload
         state.document_loading = false
      })
      addCase(getDocuments.rejected, (state) => {
         state.document_loading = false
      })

      addCase(uploadDocuments.pending, (state) => {
         state.document_status = 'pending'
         state.document_loading2 = true
      })
      addCase(uploadDocuments.fulfilled, (state) => {
         state.document_status = 'success'
         state.document_loading2 = false
      })
      addCase(uploadDocuments.rejected, (state) => {
         state.document_status = 'failed'
         state.document_loading2 = false
      })
   }
});

export const { toggleUploadDocumentModal } = documentsSlice.actions;
export default documentsSlice.reducer;