import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, updateProfile, updateProfileImage } from '../../features/authSlice';
import { loadBanks } from '../../features/bidSlice';
import { setLanguage } from '../../features/languageSlice';
import { user_man, user_woman } from '../../assets';
import translate from '../../Services/translate';
import Loading from '../common/Loading';


const MyAccount = () => {

  const dispatch = useDispatch();

  // eslint-disable-next-line
  const { language } = useSelector(({ language }) => language);
  const { auth_loading, myProfile, profile_loading, update_loading } = useSelector(({ auth }) => auth);
  const { banks, my_subscriptions } = useSelector(({ bid }) => bid);

  const [profile, setProfile] = useState({ user_profile: {}, bank_info: {} });
  const [imagePreview, setImagePreview] = useState('');
  // const [tempPhone, setTempPhone] = useState();


  useEffect(() => {
    dispatch(loadBanks())
    if(!Object.keys(myProfile).length)
      dispatch(getProfile())
    // eslint-disable-next-line
  }, [])

  const updateMyProfile = () => {
    dispatch(updateProfile(profile))
  }

  const uploadPicture = () => {
    var input = document.createElement('input');
    input.type = 'file';
    // input.accept = 'image/*'
    input.accept = '.jpg, .jpeg, .png'

    input.onchange = e => {
      if(e.target.files && e.target.files[0]){
        let reader = new FileReader()

        reader.onload = (e) => {
          setImagePreview(e.target.result)
        }
        reader.readAsDataURL(e.target.files[0])
      }
      let file = e.target.files[0]; 
      file && dispatch(updateProfileImage({ image: file })).then(() => {
        setProfile({ ...profile, user_profile: {...profile.user_profile, image_url: myProfile.img_url }})
      })
    }
    input.click();
  }

  // const modifyPhone = num => {
  //   // num?.length && setTempPhone(num[0])
  //   if(num?.length === 10){
  //       if(num[0] == 0){
  //           return '+251' + num.slice(1)
  //       }
  //   }else if(num?.length === 9){
  //       if(num[0] == 9){
  //           return '+251' + num
  //       }
  //   }
  //   return null
  // }



   if(auth_loading)
      return <div className = 'pt-12'><Loading loading = { auth_loading } loadingStyle = 'text-sky-600 w-7 h-7'/></div>

   return (
      <div className = 'pb-5 md:pb-12'>
         <div className = 'self-center pb-1 text-primary-100 font-semibold text-left pl-10 border-b border-gray-300 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(146) }</h2>
         </div>

         <div className = 'md:flex w-full p-3 '>
            <div className = 'w-full md:w-[20%] border-r border-gray-300 flex justify-center items-center'>
               <div className = 'flex md:flex-col'>
                  <div className = 'h-20 md:h-80 w-14 md:w-52 rounded-[50%] relative border border-gray-300' onClick = { () => uploadPicture() }>
                  <img 
                     src = {imagePreview ? 
                        imagePreview : 
                        myProfile?.user_profile?.image_url ? 
                        `${myProfile.user_profile.image_url}` :
                        myProfile?.user_profile?.gender === 'FEMALE' ? user_woman : user_man} 
                     alt = 'user' 
                     className = 'h-full w-full rounded-[50%] object-cover cursor-pointer' 
                  />
                  <div className = 'absolute top-[50%]  flex justify-center w-full'>
                     <Loading loading = { profile_loading } loadingStyle = 'text-white w-7 h-7'/>
                  </div>
                  </div>
                  <div className = 'pl-5 md:pl-0'>
                  <p className = 'font-semibold md:text-lg text-center pt-2 text-gray-400'>{ myProfile?.user_profile?.first_name ? myProfile.user_profile.first_name + ' ' + myProfile.user_profile.last_name : '' }</p>
                  <p className = 'font-semibold md:text-lg text-center text-gray-400'>{ myProfile?.user_profile?.email }</p>
                  </div>
               </div>
            </div>

            <div className = 'w-full md:w-[40%] border-r border-gray-300'>
               <h2 className = 'text-center text-2xl font-bold py-3 text-gray-600'>{ translate(279) }</h2>
               
               <div className = 'flex w-full justify-between px-3 md:px-5 py-2 md:py-3'>
                  <div className = 'w-1/2 pr-1 md:pr-3'>
                  <label htmlFor = '_first_name' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(97) }</label>
                  <input
                     className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded md:rounded-lg focus:outline-none focus:border-gray-500 block w-full p-1 md:p-2'
                     defaultValue = { myProfile?.user_profile?.first_name }
                     onChange = { e => setProfile({ ...profile, user_profile: {...profile.user_profile, first_name: e.target.value }}) }
                     type = 'text'
                     id = '_first_name'
                     placeholder = { translate(97) } 
                  />
                  </div>
                  <div className = 'w-1/2 pl-1 md:pl-3'>
                  <label htmlFor = '_last_name' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(99) }</label>
                  <input
                     className = 'block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-gray-500'
                     defaultValue = { myProfile?.user_profile?.last_name }
                     onChange = { e => setProfile({ ...profile, user_profile: {...profile.user_profile, last_name: e.target.value }}) }
                     type = 'text'
                     id = '_last_name'
                     placeholder = { translate(99) } 
                  />
                  </div>
               </div>

               <div className = 'flex w-full justify-between px-3 md:px-5 py-2 md:py-3'>
                  <div className = 'w-1/2 pr-1 md:pr-3'>
                  <label htmlFor = '_email' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(101) }</label>
                  <input
                     className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded md:rounded-lg focus:outline-none focus:border-gray-500 block w-full p-1 md:p-2'
                     defaultValue = { myProfile?.user_profile?.email }
                     onChange = { e => setProfile({ ...profile, user_profile: { ...profile.user_profile, email: e.target.value } }) }
                     type = 'email'
                     id = '_email'
                     placeholder = { translate(101) } 
                  />
                  </div>
                  <div className = 'w-1/2 pl-1 md:pl-3'>
                  <label htmlFor = '_phone' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(103) }</label>
                  <input 
                     className = 'block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm focus:outline-none'
                     defaultValue = { myProfile?.user_profile?.phone_number }
                     type = 'text' 
                     id = '_phone'
                     readOnly/>
                  </div>
               </div>

               <div className = 'flex w-full justify-between px-3 md:px-5 py-2 md:py-3'>
                  <div className = 'w-1/2 pr-1 md:pr-3'>
                  <label htmlFor = '_country' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(280) }</label>
                  <input 
                     defaultValue = { myProfile?.user_profile?.country } 
                     className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded md:rounded-lg focus:outline-none block w-full p-1 md:p-2'
                     type = 'text' 
                     id = '_country' 
                     readOnly />
                  </div>
                  <div className = 'w-1/2 pl-1 md:pl-3'>
                  <label htmlFor = '_region' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(281) }</label>
                  <input
                     className = 'block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-gray-500'
                     defaultValue = { myProfile?.user_profile?.address?.region ? myProfile.user_profile.address.region : '' }
                     onChange = { e => setProfile({ ...profile, user_profile: { ...profile.user_profile, address: { ...profile.user_profile.address, region: e.target.value } } }) }
                     type = 'text'
                     id = '_region'
                     placeholder = { translate(281) } 
                  />
                  </div>
               </div>
               
               <div className = 'flex w-full justify-between px-3 md:px-5 py-2 md:py-3'>
                  <div className = 'w-1/2 pr-1 md:pr-3'>
                  <label htmlFor = '_city' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(282) }</label>
                  <input
                     className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded md:rounded-lg focus:outline-none focus:border-gray-500 block w-full p-1 md:p-2'
                     defaultValue = { myProfile?.user_profile?.address?.city }
                     onChange = { e => setProfile({ ...profile, user_profile: { ...profile.user_profile, address: { ...profile.user_profile.address, city: e.target.value } } }) }
                     type = 'text'
                     id = '_city'
                     placeholder = { translate(282) }
                  />
                  </div>
                  <div className = 'w-1/2 pl-1 md:pl-3'>
                  <label htmlFor = '_birth_date' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(283) }</label>
                  <input
                     className = 'block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-gray-500'
                     defaultValue = { myProfile?.user_profile?.birth_date ? myProfile.user_profile.birth_date : '' }
                     onChange = { e => setProfile({ ...profile, user_profile: { ...profile.user_profile, birth_date: e.target.value } }) }
                     type = 'date'
                     id = '_birth_date'
                  />
                  </div>
               </div>

               <div className = 'flex w-full justify-between px-3 md:px-5 py-2 md:py-3'>
                  <div className = 'w-1/2 pr-1 md:pr-3'>
                  <label htmlFor = '_gender' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(284) }</label>
                  <select
                     className = 'block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-gray-500'
                     defaultValue = { myProfile?.user_profile?.gender }
                     onChange = { e => setProfile({ ...profile, user_profile: { ...profile.user_profile, gender: e.target.value } }) }
                     id = '_gender' 
                  >
                     <option className = 'hidden font-thin text-blue-600 hover:text-blue-600'>{ translate(284) }</option>
                     <option value = 'MALE'>{ translate(285) }</option>
                     <option value = 'FEMALE'>{ translate(286) }</option>
                  </select>
                  </div>
                  <div className = 'w-1/2 pl-1 md:pl-3'>
                  <label htmlFor = '_preferred_language' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(116) }</label>
                  <select
                     className = 'focus:outline-none block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm'
                     defaultValue = { myProfile?.user_profile?.preferred_language }
                     onChange = { e => { setProfile({ ...profile, user_profile: { ...profile.user_profile, preferred_language: e.target.value } }); dispatch(setLanguage({ lang: e.target.value }))} }
                     id = '_preferred_language'
                  >
                     <option className = 'hidden font-thin text-blue-600 hover:text-blue-600'>{ translate(116) }</option>
                     <option name = 'English' value = 'English'>English</option>
                     <option name = 'Amharic' value = 'Amharic'>አማርኛ</option>
                     <option name = 'Afaan-Oromoo' value = 'Afaan-Oromoo'>Afaan-Oromoo</option>
                  </select>
                  </div>
               </div>
            </div>

            <div className = 'w-full md:w-[40%] '>
               <h2 className = 'text-center text-2xl font-bold py-3 text-gray-600'>{ translate(287) }</h2>
               
               <div className = 'flex w-full justify-between px-3 md:px-5 py-2 md:py-3'>
                  <div className = 'w-1/2 pr-1 md:pr-3'>
                  <label htmlFor = '_bank' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(189) }</label>/
                  <select
                     className = 'focus:outline-none block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm'
                     defaultValue = { myProfile?.bank_info?.bank }
                     onChange = { e => setProfile({ ...profile, bank_info: { ...profile.bank_info, bank: e.target.value } }) }
                     id = '_bank'
                  >
                     <option className = 'hidden font-thin text-gray-600'>{ translate(190) }</option>
                     {
                        banks.map((b, i) => <option key={ i }  value = { b.id }>{ b.name }</option>)
                     }
                  </select>
                  </div>
                  <div className = 'w-1/2 pl-1 md:pl-3'>
                  <label htmlFor = '_account_holder_name' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(288) }</label>
                  <input
                     className = 'block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-gray-500'
                     defaultValue = { myProfile?.bank_info?.account_holder_name }
                     onChange = { e => setProfile({ ...profile, bank_info: { ...profile.bank_info, account_holder_name: e.target.value } }) }
                     type = 'text'
                     id = '_account_holder_name'
                     placeholder = { translate(288) } 
                  />
                  </div>
               </div>

               <div className = 'flex w-full justify-between px-3 md:px-5 py-2 md:py-3'>
                  <div className = 'w-1/2 pr-1 md:pr-3'>
                  <label htmlFor = '_account_number' className = 'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(289) }</label>
                  <input
                     className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded md:rounded-lg focus:outline-none focus:border-gray-500 block w-full p-1 md:p-2'
                     defaultValue = { myProfile?.bank_info?.account_number }
                     onChange = { e => setProfile({ ...profile, bank_info: { ...profile.bank_info, account_number: e.target.value } }) }
                     type = 'number'
                     id = '_account_number'
                     placeholder = { translate(289) } 
                  />
                  </div>
                  <div className = 'w-1/2 pl-1 md:pl-3'>
                  <label htmlFor = '_account_type' className =  'block mb-1 text-[0.7rem] font-medium text-gray-500'>{ translate(290) }</label>
                  <select
                     className = 'focus:outline-none block p-1 md:p-2 w-full text-sm text-gray-900 bg-gray-50 rounded md:rounded-lg border border-gray-300 shadow-sm'
                     defaultValue = { myProfile?.bank_info?.account_type }
                     onChange = { e => setProfile({ ...profile, bank_info: { ...profile.bank_info, account_type: e.target.value } }) } 
                     id = '_account_type'
                  >
                     <option className = 'hidden font-thin text-blue-600 hover:text-blue-600'>{ translate(291) }</option>
                     <option value = 'SAVING'>SAVING</option>
                  </select>
                  </div>
               </div>

               <h2 className = 'text-center text-2xl font-bold pt-6 text-gray-600'>{ translate(292) }</h2>

               <div className = 'grid grid-cols-1 sm:grid-cols-3 gap-4 w-full px-3 md:px-5 pt-6'>
                  {my_subscriptions.map((s, i) => (
                     <div key = { i } className = 'rounded-xl py-2 px-3 bg-gradient-to-r from-sky-700 to-gray-900 text-white hover:scale-105 duration-200'>

                        <div className = 'flex justify-between'>
                           <p className = 'text-sm'>{ translate(294) }:</p>
                           <p className = 'text-sm'>{ s?.package?.name }</p>
                        </div>

                        <div className = 'flex justify-between'>
                           <p className = 'text-sm'>{ translate(295) }:</p>
                           <p className = 'text-sm'>{ s?.package?.price }</p>
                        </div>
                        <div className = 'flex justify-between'>
                           <p className = 'text-sm'>{ translate(296) }:</p>
                           <p className = 'text-sm'>{ s?.start_date }</p>
                        </div>
                        <div className = 'flex justify-between'>
                           <p className = 'text-sm'>{ translate(297) }:</p>
                           <p className = 'text-sm'>{ s?.end_date }</p>
                        </div>
                        <div className = 'flex justify-between'>
                           <p className = 'text-sm'>{ translate(245) }:</p>
                           <p className = 'text-sm text-green-400'>{ s?.is_active ? translate(8) : translate(9) }</p>
                        </div>

                     </div>
                  ))}
               </div>
            </div>
         </div>

         <div className = 'flex justify-center md:flex-row-reverse md:justify-start pt-2 md:pt-4'>
            <div className = 'flex justify-center w-full md:w-[80%]'>
               <button 
                  onClick = { () => updateMyProfile() } 
                  className = 'bg-secondary-50 hover:bg-sky-500 w-72 text-white rounded-full border drop-shadow py-2'
               >
                  {update_loading ? 
                     <Loading loading = { update_loading } loadingStyle = 'h-6 w-6 text-white'/>
                     : 
                     translate(293) 
                  }
               </button>
            </div>
         </div>
      </div>
   )
}

export default MyAccount;