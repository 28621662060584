import { useState, useMemo } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Loading from '../common/Loading';
import { googleMaps_ApiKey } from '../../Services/apiConstants';


const Map = ({ center, img, heading, tooltipTitle, description }) => {

  const marker = useMemo(() => (center), [center])
  const [infoOpen, setInfoOpen] = useState(false);
  const [zoom, setZoom] = useState(13);
  
  const markerClickHandler = e => {
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
    if (zoom <= 13) {
      setZoom(14);
    }
  };

  return (
    <GoogleMap
      zoom = { zoom }
      center = { marker }
      mapContainerClassName = 'mapping'
    >
      <Marker 
        // title = 'item location'
        title = { tooltipTitle }
        position = { marker }
        onClick = { e => markerClickHandler(e) }
      />

      {infoOpen && (
        <InfoWindow
          className = 'map-info-window'
          position = { center }
          options = {{ pixelOffset: new window.google.maps.Size(0, -40)}}
          onCloseClick = { () => setInfoOpen(false) }
          // heading = { heading }
        >
          <div className = ''>
            <h3 className = 'font-semibold'>{ heading }</h3>
            <div className = 'flex pt-1'>
              <img src = { img } alt = '' className = 'w-16 hover:scale-150 duration-200 origin-left info'/>
              <div className = 'w-32 pl-4 text-xs'>{ description }</div>
            </div>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  )
}

const Mapping = ({ center, img, heading, tooltipTitle, description }) => {
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: 'AIzaSyAOYhyqucDKYkd7kg2dNSFzYpEhg9drlWc'
    // googleMapsApiKey: 'AIzaSyAdIFpV6ADuHKNxMeex8rO_fJ5z_PWa7WM'
    googleMapsApiKey: googleMaps_ApiKey
  });

  if(!isLoaded)
    return (
      <div className = 'mapping flex justify-center items-center'>
        <Loading loading = { !isLoaded } loadingStyle = 'text-sky-600 w-7 h-7'/>
      </div>
    )
  return <Map center = { center } img = { img } heading = { heading } tooltipTitle = { tooltipTitle } description = { description }/>
}

export default Mapping;