import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import myModel from '../Services/api';
import { toast } from 'react-hot-toast';
import translate from '../Services/translate';

const initialState = {
   bid_loading: false,
   upload_loading: false,
   balance_loading: false,
   subscriptionCheck_loading: false,
   bids: [],
   banks: [],
   balance: {},
   bankReceipt: null,
   show_bid_submit_modal: false,
   show_one_time_subscription_modal: false,
   show_bid_success_modal: false,
   show_bid_security_modal: false,
   show_upload_receipt_modal: false,
   bid_status: '',
   bidding_status: '',
   bidding_loading: false,
   bid_security_status: '',
   bid_security_loading: false,
   balance_status: '',
   dont_show_side_popup: false,
   myBidsLight: [],
   payment_platforms: [],
   subscription_packages: [],
   subscription_loading: false,
   checkout_url: null,
   submit_package_loading: false,
   is_subscription_started: false,
   subscriptionCheck_status: null,
   my_subscriptions: [],
   cpo: [],
   deposit: [],
   withdraw: [],
   returnables: [], 
   bid_security_online_checkout_url: null,
   show_request_refund_modal: false,
   refund_loading: false
}

export const loadMyBids = createAsyncThunk(
   'bid/loadMyBids',
   async (arg, { rejectWithValue }) => {
      try {
         const response = await myModel.find('my-bids')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const submitBid = createAsyncThunk(
   'bid/submitBid',
   async (param, { rejectWithValue }) => {
      const {id, ...rest} = param
      try {
         const response = await myModel.create(`submit-bid`, id, rest)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const submitBidSecurity = createAsyncThunk(
   'bid/submitBidSecurity',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.upload(`submit-bid-security/${param.id}`, param)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const loadBanks = createAsyncThunk(
   'bid/loadBanks',
   async (arg, { rejectWithValue }) => {
      try {
         const response = await myModel.find(`banks`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const uploadBankReceipt = createAsyncThunk(
   'bid/uploadBankReceipt',
   async (arg, { rejectWithValue }) => {
      try {
         const response = await myModel.upload(`upload-bank-transfer-receipt`, arg)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getMyBalance = createAsyncThunk(
   'bid/getMyBalance',
   async (arg, { rejectWithValue }) => {
      try {
         const response = await myModel.find('my-balance')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const loadTransactions= createAsyncThunk(
   'bid/loadTransactions',
   async (arg, { rejectWithValue }) => {
      try {
         const cpo = await myModel.find('transaction/cpo')
         const deposit = await myModel.find('transaction/deposit')
         const withdraw = await myModel.find('transaction/withdraw')
         const returnables = await myModel.find('transaction/returnables')
         return { cpo, deposit, withdraw, returnables }
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const loadBidsLight = createAsyncThunk(
   'bid/loadBidsLight',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`my-bids/light`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const checkSubscriptionStarted = createAsyncThunk(
   'bid/checkSubscriptionStarted',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`system-params`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getSubscriptionPackages = createAsyncThunk(
   'bid/getSubscriptionPackages',
   async (args, { rejectWithValue } ) => {
      console.log("args",args)
      try {
         const response = await myModel.find(`payment`, args?"subscription-packages?auction_id="+args:"subscription-packages")
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getPaymentPlatforms = createAsyncThunk(
   'bid/getPaymentPlatforms',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`payment/payment-platforms`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const submitPackage = createAsyncThunk(
   'bid/submitPackage',
   async (data, { rejectWithValue } ) => {
      try {
         const response = await myModel.create(`payment/subscription`, '', data)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getMySubscriptions = createAsyncThunk(
   'bid/getMySubscriptions',
   async (data, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`payment/my-subscriptions`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const subscriptionCheck = createAsyncThunk(
   'bid/subscriptionCheck',
   async (data, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`subscription-check`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const submitBidSecurityOnline = createAsyncThunk(
   'bid/submitBidSecurityOnline',
   async (data, { rejectWithValue } ) => {
      try {
         const response = await myModel.create(`payment/cpo/${data.auction_id}/${data.payment_platform_id}`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const requestRefund = createAsyncThunk(
   'bid/requestRefund',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.create(`payment/request-withdraw`, '', param)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const bidSlice = createSlice({
   name: 'bid',
   initialState,
   reducers: {
      toggleBidSubmitModal: (state, { payload: { value } }) => {
         state.show_bid_submit_modal = value
      },
      toggleOneTimeModal: (state, { payload: { value } }) => {
         state.show_one_time_subscription_modal = value
      },
      toggleBidSuccessModal: (state, { payload: { value } }) => {
         state.show_bid_success_modal = value
         state.bidding_status = ''
      },
      toggleBidSecurityModal: (state, { payload: { value } }) => {
         state.show_bid_security_modal = value
      },
      toggleUploadReceiptModal: (state, { payload: { value } }) => {
         state.show_upload_receipt_modal = value
      },
      updateBidStatus: (state, { payload: { value } }) => {
         state.bidding_status = value
      },
      updateBidPrice: (state, { payload: { auction_id, price, userId, _userId, ws_no_of_bids } }) => {
         const idx = state.bids.findIndex(b => b.auction.id === auction_id)
         if (idx >= 0) {
            const selected = state.bids[idx]
            selected.auction.current_price = price;
            selected.auction.no_of_bids = ws_no_of_bids + 1
            if(userId === _userId){
               selected.my_bid_value = price
            }
            state.bids[idx] = selected;
         }
      },
      setDontShowSidePopup: (state, { payload }) => {
         state.dont_show_side_popup = payload
      },
      resetCheckoutUrl: (state) => {
         state.checkout_url = null
         state.bid_security_online_checkout_url = null
      },
      toggleRequestRefundModal: (state, { payload: { value } }) => {
         state.show_request_refund_modal = value
      },
      updateBidEndTime: (state, { payload: { id, endTime } }) => {
         const idx = state.bids.findIndex(b => b.auction.id === id)
         if (idx >= 0) {
            const selected = state.bids[idx]
            selected.auction.end_time = endTime;
            state.bids[idx] = selected;
         }
      }
   },
   extraReducers: ({ addCase }) => {
      addCase(loadMyBids.pending, (state) => {
         state.bid_loading = true
         state.bid_status = 'pending'
      })
      addCase(loadMyBids.fulfilled, (state, { payload }) => {
         state.bids = payload
         state.bid_status = 'success'
         state.bid_loading = false
      })
      addCase(loadMyBids.rejected, (state) => {
         state.bid_status = 'rejected'
         state.bid_loading = false
      })

      addCase(submitBid.pending, (state) => {
         state.bidding_loading = true
         state.bidding_status = 'pending'
      })
      addCase(submitBid.fulfilled, (state, { payload }) => {
         state.bidding_status = 'success'
         state.bidding_loading = false
      })
      addCase(submitBid.rejected, (state, { payload }) => {
         state.bidding_status = 'rejected'
         state.bidding_loading = false
      })

      addCase(submitBidSecurity.pending, (state) => {
         state.bid_security_loading = true
         state.bid_security_status = 'pending'
      })
      addCase(submitBidSecurity.fulfilled, (state) => {
         state.bid_security_status = 'success'
         state.bid_security_loading = false
         state.show_bid_security_modal = false
         toast.success(translate(328), { duration: 7000 })
      })
      addCase(submitBidSecurity.rejected, (state) => {
         state.bid_security_status = 'rejected'
         state.bid_security_loading = false
      })

      addCase(loadBanks.pending, (state) => {
         state.bid_loading = true
         state.bid_status = 'pending'
      })
      addCase(loadBanks.fulfilled, (state, { payload }) => {
         state.banks = payload
         state.bid_status = 'success'
         state.bid_loading = false
      })
      addCase(loadBanks.rejected, (state) => {
         state.bid_status = 'rejected'
         state.bid_loading = false
      })

      addCase(uploadBankReceipt.pending, (state) => {
         state.upload_loading = true
         state.bid_status = 'pending'
      })
      addCase(uploadBankReceipt.fulfilled, (state, { payload }) => {
         state.bankReceipt = payload
         state.bid_status = 'success'
         state.upload_loading = false
      })
      addCase(uploadBankReceipt.rejected, (state) => {
         state.bid_status = 'rejected'
         state.upload_loading = false
      })

      addCase(getMyBalance.pending, (state) => {
         state.balance_loading = true
         state.balance_status = 'pending'
      })
      addCase(getMyBalance.fulfilled, (state, { payload }) => {
         state.balance = payload
         state.balance_status = 'success'
         // state.balance_loading = false
      })
      addCase(getMyBalance.rejected, (state) => {
         state.balance_status = 'rejected'
         state.balance_loading = false
      })

      addCase(loadTransactions.pending, (state) => {
         // state.balance_loading = true
      })
      addCase(loadTransactions.fulfilled, (state, { payload }) => {
         state.deposit = payload.deposit
         state.cpo = payload.cpo
         state.withdraw = payload.withdraw
         state.returnables = payload.returnables
         state.balance_loading = false
      })
      addCase(loadTransactions.rejected, (state) => {
         state.balance_loading = false
      })

      addCase(loadBidsLight.fulfilled, (state, { payload }) => {
         state.myBidsLight = payload
      })

      addCase(checkSubscriptionStarted.pending, (state) => {
         // state.is_subscription_started = true
      })
      addCase(checkSubscriptionStarted.fulfilled, (state, { payload }) => {
         state.is_subscription_started = payload?.require_subscription
      })
      addCase(checkSubscriptionStarted.rejected, (state) => {
         // state.subscription_loading = false
      })

      addCase(getSubscriptionPackages.pending, (state) => {
         state.subscription_loading = true
      })
      addCase(getSubscriptionPackages.fulfilled, (state, { payload }) => {
         state.subscription_packages = payload
         state.subscription_loading = false
      })
      addCase(getSubscriptionPackages.rejected, (state) => {
         state.subscription_loading = false
      })

      addCase(getPaymentPlatforms.pending, (state) => {
         // state.subscription_loading = true
      })
      addCase(getPaymentPlatforms.fulfilled, (state, { payload }) => {
         state.payment_platforms = payload
         // state.subscription_loading = false
      })
      addCase(getPaymentPlatforms.rejected, (state) => {
         // state.subscription_loading = false
      })

      addCase(submitPackage.pending, (state) => {
         state.submit_package_loading = true
      })
      addCase(submitPackage.fulfilled, (state, { payload }) => {
         state.checkout_url = payload.checkout_url
         state.submit_package_loading = false
      })
      addCase(submitPackage.rejected, (state) => {
         state.submit_package_loading = false
         toast.error(translate(325), { duration: 3000, id: 'copy' })
      })

      // addCase(getMySubscriptions.pending, (state) => {
      //    state.submit_package_loading = true
      // })
      addCase(getMySubscriptions.fulfilled, (state, { payload }) => {
         state.my_subscriptions = payload
      })
      // addCase(getMySubscriptions.rejected, (state) => {
      //    state.submit_package_loading = false
      //    toast.error('Somethion wrong, please try again.', { duration: 3000, id: 'copy' })
      // })

      addCase(subscriptionCheck.pending, (state) => {
         state.subscriptionCheck_loading = true
      })
      addCase(subscriptionCheck.fulfilled, (state, { payload }) => {
         state.subscriptionCheck_status = payload?.user_has_subscription
         state.subscriptionCheck_loading = false
      })
      addCase(subscriptionCheck.rejected, (state) => {
         state.subscriptionCheck_loading = false
      })

      addCase(submitBidSecurityOnline.pending, (state) => {
         state.bid_security_loading = true
         // state.bid_security_status = 'pending'
      })
      addCase(submitBidSecurityOnline.fulfilled, (state, { payload }) => {
         if(payload?.checkout_url){
            state.bid_security_online_checkout_url = payload.checkout_url
            state.bid_security_loading = false
            state.show_bid_security_modal = false
         }else{
            state.bid_security_loading = false
            toast.error('Error: connecting to payment gateways, please try again', { duration: 3000, id: 'copy'})
            state.show_bid_security_modal = false
         }
      })
      addCase(submitBidSecurityOnline.rejected, (state) => {
         // state.bid_security_status = 'rejected'
         state.bid_security_loading = false
         // state.show_bid_security_modal = false
      })

      addCase(requestRefund.pending, (state) => {
         state.refund_loading = true
      })
      addCase(requestRefund.fulfilled, (state, { payload }) => {
         if(payload?.status === 200){
            toast.success('Your withdrawal request has been submitted successfully. Your refund request is being processed. Thank you!', { duration: 7000, id: 'copy' })
         }
         state.refund_loading = false
         state.show_request_refund_modal = false
      })
      addCase(requestRefund.rejected, (state) => {
         state.refund_loading = false
         toast.success('Something went wrong, please try again', { duration: 3000, id: 'copy' })
      })

   }
});

export const { toggleBidSubmitModal,toggleOneTimeModal, toggleBidSuccessModal, toggleBidSecurityModal, toggleUploadReceiptModal, updateBidStatus, updateBidPrice, setDontShowSidePopup, resetCheckoutUrl, toggleRequestRefundModal, updateBidEndTime } = bidSlice.actions;
export default bidSlice.reducer;