import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyBalance, loadBanks, loadTransactions, toggleRequestRefundModal, toggleUploadReceiptModal } from '../../features/bidSlice';
import dayjs from 'dayjs';
import translate from '../../Services/translate';
import Loading from '../common/Loading';
import Modal from '../common/Modal';
import { opt } from '../../utils';
import { BsFullscreen } from "react-icons/bs";
import { FiX } from 'react-icons/fi';
import toast from 'react-hot-toast';


const MyBalance = () => {

   const dispatch = useDispatch();

  // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { balance, balance_loading, bid_loading, cpo, deposit, withdraw, returnables } = useSelector(({ bid }) => bid);

   const [selectedTab, setSelectedTab] = useState('deposit');
   const [viewFile, setViewFile] = useState(false);

   const openFullscreen = (id) => {
      let elem = document.getElementById(id);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
   }

   const goToAddNewDeposit = () => {
      dispatch(loadBanks()).then(() => {
         dispatch(toggleUploadReceiptModal({ value: true }))
      })
   }

   useEffect(() => {
      dispatch(getMyBalance())
      dispatch(loadTransactions())
      // eslint-disable-next-line
   }, [])


   return (
      <div className = 'pb-8'>
         <div className = 'self-center text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(131) }</h2>
         </div>
         
         {
            !balance_loading
            ?
            <>
               <div className = 'sm:flex justify-between pt-3'>
               <div className = 'text-xl md:text-2xl text-gray-600 font-semibold pt- w-full sm:w-2/3 flex justify-center'>{ translate(168) }</div>
                  <div className = 'flex justify-end pr-2 sm:pr-6 gap-3 pt-2 sm:pt-0'>
                     <button
                        onClick = { () => goToAddNewDeposit() }
                        className = 'bg-primary-100 text-xs md:text-base rounded-md px-2 sm:px-3 sm:w-44 py-2 text-white hover:bg-lime-700'
                        name = 'add_new_deposit'
                     >
                        { bid_loading ? <Loading loading = { bid_loading } loadingStyle = 'text-white w-5 h-5' /> : translate(199) }
                     </button>
                     <button
                        onClick = { () => {balance.available_balance > 0 ? dispatch(toggleRequestRefundModal({ value: true })) : toast.error('You do not have sufficient balance to withdraw', { duration: 3000, id: 'copy'})} }
                        className = 'bg-primary-100 text-xs md:text-base rounded-md px-2 sm:px-3 sm:w-44 py-2 text-white hover:bg-lime-700'
                        name = 'add_new_deposit'
                     >
                        { translate(340) }
                     </button>
                  </div>
               </div>

               <div className = 'flex w-full justify-center pb-5 pt-3 sm:pt-6'>
                  <div className = 'w-[94%] sm:w-[70%] flex justify-between'>
                     <div>
                        <h1 className = 'text-bold text-gray-500 text-sm sm:text-base md:text-xl'>{ translate(249) }</h1>
                        <p className = 'text-base sm:text-lg md:text-xl text-sky-500 text-center font-semibold'>{ balance.total_deposit ? balance.total_deposit.toLocaleString('en', opt) : '0.00'}
                           <span className = 'text-gray-500 text-xs sm:text-sm font-normal'> { translate(4) }</span>
                        </p>
                     </div>

                     <div>
                        <h1 className = 'text-bold text-gray-500 text-sm sm:text-base md:text-xl'>{ translate(250) }</h1>
                        <p className = 'text-base sm:text-lg md:text-xl text-sky-500 text-center font-semibold'>{ balance.available_balance ? balance.available_balance.toLocaleString('en', opt) : '0.00'}
                           <span className = 'text-gray-500 text-xs sm:text-sm font-normal'> { translate(4) }</span>
                        </p>
                     </div>
                  </div>
               </div>

 
               <div className = 'px-2 sm:px-6'>
                  <div className = {` border-sky-60 sm:flex justify-center`}>
                     <nav className ="flex flex-row justify-between w-full sm:w-[80%] bg-gray-100 text-xs sm:text-base">
                        <button onClick = {() => setSelectedTab('deposit')} className = {`text-gray-600 py-2 sm:py-4 flex-1 block hover:text-sky-600 hover:bg-gray-200 focus:outline-none border ${selectedTab === 'deposit' ? 'border-t-sky-600 border-x-sky-600 font-medium bg-white rounded-t-lg' : 'border-b-sky-600 border-x-white'}`}>
                           { translate(251) }
                        </button>
                        <button onClick = {() => setSelectedTab('cpo')} className = {`text-gray-600 py-2 sm:py-4 flex-1 block hover:text-sky-600 hover:bg-gray-200 focus:outline-none border ${selectedTab === 'cpo' ? 'border-t-sky-600 border-x-sky-600 font-medium bg-white rounded-t-lg' : 'border-b-sky-600 border-x-white'}`}>
                           { translate(186) }
                        </button>
                        <button onClick = {() => setSelectedTab('returnables')} className = {`text-gray-600 py-2 sm:py-4 flex-1 block hover:text-sky-600 hover:bg-gray-200 focus:outline-none border ${selectedTab === 'returnables' ? 'border-t-sky-600 border-x-sky-600 font-medium bg-white rounded-t-lg' : 'border-b-sky-600 border-x-white'}`}>
                           { translate(252) }
                        </button>
                        <button onClick = {() => setSelectedTab('withdraw')} className = {`text-gray-600 py-2 sm:py-4 flex-1 block hover:text-sky-600 hover:bg-gray-200 focus:outline-none border ${selectedTab === 'withdraw' ? 'border-t-sky-600 border-x-sky-600 font-medium bg-white rounded-t-lg' : 'border-b-sky-600 border-x-white'}`}>
                           { translate(253) }
                        </button>
                     </nav>
                  </div>
               </div>

               <div className = 'w-full flex flex-col justify-center items-center gap-2 pt-6 px-2 sm:px-6'>
               {selectedTab === 'deposit' ?
                  deposit.map((d, i) => (
                     <div key = { i } className = 'w-full sm:w-[80%] flex flex-col gap-2'>      
                        <div className = 'border border-gray-300 shadow-md shadow-gray-300 rounded py-1 flex justify-between pl-2 pr-1 bg-white cursor-default'>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(254) }</p>
                              <p className = 'text-[9px] sm:text-sm font-semibold text-sky-600 text-center'>{ d?.created_at ? dayjs(d.created_at).format('MMM D, YYYY') : null }</p>
                           </div>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(255) }</p>
                              <p className = 'text-[9px] sm:text-sm font-semibold text-sky-600 text-center'>{ d?.transaction_type ? d.transaction_type : ''}</p>
                           </div>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(256) }</p>
                              <p className = 'text-[9px] sm:text-sm font-semibold text-sky-600 text-center'>
                                 <span className = 'text-xs sm:text-sm font-semibold text-sky-600'> { d?.amount ? d.amount.toLocaleString('en', opt) : '-'}</span> 
                                 {/* <span className = 'text-sky-600 text-xs font-normal'> { translate(4) }</span> */}
                              </p>
                           </div>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(257) }</p>
                              <p className = {`text-[9px] sm:text-sm font-semibold ${d?.approved ? 'text-green-500' : 'text-sky-600'} text-center`}>{ d?.approved ? 'Yes' : 'No' }</p>
                           </div>
                           
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(258) }</p>
                              <p className = {`text-[9px] sm:text-sm font-semibold ${d?.is_void ? 'text-red-500' : 'text-sky-600'} text-center`}>{ d?.is_void ? 'Yes' : 'No' }</p>
                           </div>
                           {d?.scan_file ?
                              <button className = 'bg-slate-600 hover:bg-lime-900 text-white text-center hover:border-sky-600 w-16 sm:w-32 text-[10px] sm:text-sm cursor-pointer border border-gray-400 rounded-r' 
                                 onClick = {() => {setViewFile(true)}}>
                                 { translate(259) }
                              </button>
                              : 
                              null 
                           }
                        </div>

                        <Modal visible = { viewFile } customWidth = 'w-[94%] md:w-[45%] overflow-hidden'>
                           <div className = 'w-full flex flex-col justify-center bg-black/90'>
                              <div className = ' w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg flex justify-between'>
                                 <div className = ' text-white text-lg font-semibold pl-3 md:pl-6'>{ translate(260) }</div> 
                                 <div className = 'pr-4'> 
                                    <FiX className = 'text-gray-800 text-xl cursor-pointer hover:scale-105 hover:text-red-500' onClick = { () => setViewFile(false) }/>
                                 </div>
                              </div>

                              <img src = { d?.scan_file } alt = 'receipt' className = 'pt-3 h-96 object-contain' id = {`doc-1-${i}`}/>

                              <div className = 'w-full flex justify-between px-5 py-4'>
                                 <div className = 'h-full flex items-center w-16 pt-1'>
                                    <BsFullscreen className = 'text-[30px] text-stone-400 hover:text-sky-600 cursor-pointer' onClick = {() => openFullscreen(`doc-1-${i}`) }/>
                                 </div>
                                 <button
                                    className = 'rounded border border-stone-400 px-2 sm:px-10 py-1 bg-stone-300 hover:bg-stone-500 hover:text-white'
                                    onClick = { () => setViewFile(false) }
                                 >
                                    { translate(222) }
                                 </button>
                              </div>
                           </div>
                        </Modal> 
                     </div>
                  ))
                  : selectedTab === 'cpo' ? (
                     cpo.map((c, i) => (
                     <div key = { i } className = 'w-full sm:w-[80%] flex flex-col gap-2'>
                        <div className = ' border border-gray-300 shadow-md shadow-gray-300 rounded py-1 flex justify-between pl-2 pr-1 bg-white cursor-default'>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(261) }</p>
                              <p className = 'text-[9px] sm:text-sm font-semibold text-sky-600 text-center'>{ c?.created_at ? dayjs(c.created_at).format('MMM D, YYYY') : null }</p>
                           </div>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(195) }</p>
                              <p className = 'text-[9px] sm:text-sm font-semibold text-sky-600 text-center'> { c?.cpo_number }</p>
                           </div>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(256) }</p>
                              <p className = 'text-[9px] sm:text-sm  text-center'><span className = 'text-sm font-semibold text-sky-600'> { c?.amount ? c.amount.toLocaleString('en', opt) : '-' }</span>
                                 {/* <span className = 'text-sky-600 text-xs font-normal'> { translate(4) }</span> */}
                              </p>
                           </div>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-[9px] sm:text-xs truncate text-center'>{ translate(262) }</p>
                              <p className = 'text-[9px] sm:text-sm font-semibold text-sky-600 text-center'>{ c?.is_refunded ? 'Yes' : 'No' }</p>
                           </div>
                           {c?.scan_file ?
                              <button className = 'bg-slate-600 hover:bg-lime-900 text-white hover:border-sky-600 w-16 sm:w-32 text-[10px] sm:text-sm text-center cursor-pointer border border-gray-400 rounded-r' 
                              onClick = {() => setViewFile(true)}>
                                 { translate(263) }
                              </button>
                              : 
                              null 
                           }
                        </div>

                        <Modal visible = { viewFile } customWidth = 'w-[94%] md:w-[45%] overflow-hidden'>
                           <div className = 'w-full flex flex-col justify-center bg-black/90'>
                              <div className = ' w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg flex justify-between'>
                                 <div className = 'text-white text-lg font-semibold pl-3 md:pl-6'>{ translate(264) }</div> 

                                 <div className = 'pr-4'> 
                                    <FiX className = 'text-gray-800 text-xl cursor-pointer hover:scale-105 hover:text-red-500' onClick = { () => setViewFile(false) }/>
                                 </div>
                              </div>

                              <img src = { c?.scan_file } alt = 'receipt' className = 'pt-3 h-96 object-contain' id = {`doc-2-${i}`}/>

                              <div className = 'w-full flex justify-between px-5 py-4'>
                                 <div className = 'h-full flex items-center w-16 pt-1'>
                                    <BsFullscreen className = 'text-[30px] text-stone-400 hover:text-sky-600 cursor-pointer' onClick = {() => openFullscreen(`doc-2-${i}`) }/>
                                 </div>
                                 <button
                                    className = 'rounded border border-stone-400 px-6 md:px-10 py-1 hover:bg-stone-500 hover:text-white'
                                    onClick = { () => setViewFile(false) }
                                 >
                                    { translate(222) }
                                 </button>
                              </div>
                           </div>
                        </Modal> 
                     </div>)))
                  : selectedTab === 'withdraw' ? (
                     withdraw.map((w, i) => (
                     <div key = { i } className = 'w-full sm:w-[80%] flex flex-col gap-2'>
                        <div className = 'border border-gray-300 shadow-md shadow-gray-300 rounded py-1 flex justify-between px-2 bg-white cursor-default'>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-xs text-center truncate'>{ translate(261) }</p>
                              <p className = 'text-sm font-semibold text-sky-600'>{ w?.created_at ? dayjs(w.created_at).format('MMM D, YYYY') : null }</p>
                           </div>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-xs text-left truncate'>{ translate(255) }</p>
                              <p className = 'text-sm font-semibold text-sky-600'>{ w?.transaction_type ? w.transaction_type : ''}</p>
                           </div>
                           <div className = 'flex flex-col justify-between'>
                              <p className = 'text-xs text-left truncate'>{ translate(256) }</p>
                              <p className = 'text-sm '><span className = 'text-sm font-semibold text-sky-600'> { w?.amount ? w.amount.toLocaleString('en', opt) : '-' }</span> 
                                 <span className = 'text-sky-600 text-sm font-normal'> { translate(4) }</span>
                              </p>
                           </div>
                        </div>
                     </div>)))
                  : selectedTab === 'returnables' ? (
                     returnables.map((r, i) => (
                  <div key = { i } className = 'w-full sm:w-[80%] flex flex-col gap-2'>
                     <div className = 'border border-gray-300 shadow-md shadow-gray-300 rounded py-1 flex justify-between px-6 bg-white cursor-default'>
                        <div className = 'flex flex-col justify-between'>
                           <p className = 'text-xs text-left truncate'>{ translate(256) }</p>
                           <p className = 'text-sm font-semibold text-sky-600'><span className = 'text-sm font-semibold text-sky-600'> {r?.amount ? r.amount.toLocaleString('en', opt) : ''}</span> 
                              <span className = 'text-sky-600 text-sm font-normal'> { translate(4) }</span>
                           </p>
                        </div>
                        <div className = 'flex flex-col justify-between'>
                           <p className = 'text-xs text-left truncate'>Auction Id</p>
                           <p className = 'text-sm font-semibold text-sky-600 text-center'>{r?.auction ? r.auction : ''}</p>
                        </div>
                     </div>
                  </div>))) : null
               }

               </div>

            </>
            :
            <div className = 'pt-12'><Loading loading = { balance_loading } loadingStyle = 'text-sky-600 w-7 h-7'/> </div>
         }
      </div>
   )
}

export default MyBalance;

