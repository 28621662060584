import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import toast from 'react-hot-toast';
import myModel from '../Services/api';
import AuthService from '../Services/AuthService';
import translate from '../Services/translate';

const initialState = {
  token: AuthService.getToken(),
  auth_loading: false,
  profile_loading: false,
  update_loading: false,
  password_loading: false,
  change_phone_loading: false,
  user: null,
  myProfile: {},
  isUsernameAvailable: false,
  username_status: '',
  register_status: '',
  login_status: '',
  profile_status: '',
  password_status: '',
  resend_status: '',
  change_phone_status: '',
  login_error: '',
  register_error: null,
  reset_error: '',
  remember: false,
  activation_message: '',
  img_url: '',
  show_chang_password_modal: false,
  delete_loading: false,
  delete_status: '',
  failedLoginAttempts: 0,
  available_in: '',
  questionnaire: [],
  questionnaire_loading: false,
  show_questionnaire_popup: false,
  submitted: false
}

export const checkUsername = createAsyncThunk(
   'auth/checkUsername',
   async (value, { rejectWithValue }) => {
      try {
         const result = await myModel.create('check-username', '', value)
         return result
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const register = createAsyncThunk(
   'auth/register',
   async (value, {rejectWithValue}) => {
      try {
         const user = await myModel.create('create-user', '', value)
         return user
      } catch (error) {
         return rejectWithValue(error)
      }
   }
) 

export const login = createAsyncThunk(
   'auth/login',
   async (value, {rejectWithValue}) => {
      try {
         const token = await myModel.create('login/', '', value)
         return token
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const confirmActivationCode = createAsyncThunk(
   'auth/confirmActivationCode',
   async (value, {rejectWithValue}) => {
      try {
         const response = await myModel.create('activate-account', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const resendCode = createAsyncThunk(
   'auth/resendCode',
   async (value, {rejectWithValue}) => {
      try {
         const response = await myModel.create('activate-account/resend-code', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getProfile = createAsyncThunk(
   'auth/getProfile',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.find('profile')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const updateProfile = createAsyncThunk(
   'auth/updateProfile',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.update('profile', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const updateProfileImage = createAsyncThunk(
   'auth/updateProfileImage',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.upload('profile/update-profile-image', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.create('profile/forgot-password', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const resetPassword = createAsyncThunk(
   'auth/resetPassword',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.create('profile/reset-password', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const changePassword = createAsyncThunk(
   'auth/changePassword',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.create('profile/change-password', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const changePhoneNumber = createAsyncThunk(
   'auth/changePhoneNumber',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.create('create-user/change-phone', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)
export const deleteAccount = createAsyncThunk(
   'auth/deleteAccount',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.destroy('profile/delete-account')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)
export const getQuestionnaire = createAsyncThunk(
   'auth/getQuestionnaire',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.find('system/questionnaire')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)
export const postQuestionnaire = createAsyncThunk(
   'auth/postQuestionnaire',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.create('system/questionnaire/post-user-answer-multiple', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      setRemember: (state, { payload: { value } }) => {
         state.remember = value
      },
      logOut: (state) => {
         AuthService.clearSelectedCategory()
         AuthService.clearToken()
         state.token = ''
         AuthService.clearShowSidePopup()
         AuthService.clearShowTutoPrompt()
         // AuthService.clearQuestionnaireSubmitted()
      },
      resetCheckUsername: (state) => {
         state.isUsernameAvailable = false
         state.username_status = ''
      },
      setLoginError: (state, { payload: { msg } }) => {
         state.login_error = msg
      },
      resetLoginStatus: (state) => {
         state.login_status = ''
      },
      setUsernameError: (state, { payload }) => {
         state.username_status = payload
      },
      toggleChangePasswordModal: (state, { payload: { value } }) => {
         state.show_chang_password_modal = value
      },
      resetRegisterError: (state, { payload }) => {
         state.register_error = { ...state.register_error, [payload.field]: '' }
      },
      resetActivationMessage: (state) => {
         state.activation_message = ''
      },
      resetRegisterStatus: (state) => {
         state.register_status = ''
      },
      updateToken: (state, { payload }) => {
         const jwt = jwtDecode(payload.access)
         const obj = { userId: jwt.user_id, user: jwt.name, token: payload }
         state.user = jwt.name
         state.token = obj
      },
      resetDeleteStatus: (state) => {
         state.delete_status = ''
      },
      toggleQuestionnairePopup: (state, { payload }) => {
         state.show_questionnaire_popup = payload
      },
      resetSubmitted: (state) => {
         state.questionnaire = []
         state.submitted = false
      }
   },
   extraReducers: ({ addCase }) => {
      addCase(checkUsername.pending, (state) => {
         state.username_status = 'checking'
      })
      addCase(checkUsername.fulfilled, (state, { payload }) => {
         if(payload.status === true){
            state.username_status = 'Username Available'
            state.isUsernameAvailable = true
         }
         else{
            state.username_status = 'Username is Taken'
            state.isUsernameAvailable = false
         }
      })
      addCase(checkUsername.rejected, (state, { payload }) => {
         state.username_status = payload?.data?.status
         state.isUsernameAvailable = false
      })

      addCase(register.pending, (state) => {
         state.auth_loading = true
         state.register_status = 'pending'
      })
      addCase(register.fulfilled, (state, { payload }) => {
         state.user = payload
         state.register_status = 'success'
         state.auth_loading = false
      })
      addCase(register.rejected, (state, { payload }) => {
         state.register_error = payload.data
         state.register_status = 'rejected'
         state.auth_loading = false
      })

      addCase(login.pending, (state) => {
         state.auth_loading = true
         state.login_status = 'pending'
      })
      addCase(login.fulfilled, (state, { payload }) => {
         if(payload){
            const jwt = jwtDecode(payload.access)
            const obj = { userId: jwt.user_id, user: jwt.name, token: payload }
            state.user = jwt.name
            state.token = obj
            AuthService.setToken(obj, state.remember)
            state.login_status = 'success'
            state.auth_loading = false
         }
      })
      addCase(login.rejected, (state, { payload }) => {
         let msg = ''
         let available = ''
         if((payload?.data?.detail === 'No active account found with the given credentials') || payload?.status === 401) {
            msg = translate(144)
         }
         if((payload?.data?.message === 'request limit exceeded') || payload?.status === 429) {
            msg = translate(315)
            available = payload.data.availableIn
         }
         state.login_error = msg
         state.available_in = available
         state.auth_loading = false
      })

      addCase(confirmActivationCode.pending, (state) => {
         state.auth_loading = true
         state.activation_message = 'checking'
      })
      addCase(confirmActivationCode.fulfilled, (state) => {
         state.activation_message = 'correct'
         state.auth_loading = false
      })
      addCase(confirmActivationCode.rejected, (state, { payload }) => {
         state.activation_message = 'wrong'
         state.auth_loading = false
      })

      addCase(resendCode.pending, (state) => {
         state.auth_loading = true
      })
      addCase(resendCode.fulfilled, (state) => {
         state.resend_status = 'success'
         state.auth_loading = false
      })
      addCase(resendCode.rejected, (state) => {
         state.auth_loading = false
      })

      addCase(getProfile.pending, (state) => {
         state.auth_loading = true
      })
      addCase(getProfile.fulfilled, (state, { payload }) => {
         state.myProfile = payload
         state.profile_status = 'success'
         state.auth_loading = false
      })
      addCase(getProfile.rejected, (state) => {
         state.auth_loading = false
      })

      addCase(updateProfile.pending, (state) => {
         state.update_loading = true
      })
      addCase(updateProfile.fulfilled, (state, { payload }) => {
         state.myProfile = payload
         state.update_loading = false
         toast.success(translate(318), { duration: 2000 })
      })
      addCase(updateProfile.rejected, (state) => {
         state.update_loading = false
      })

      addCase(updateProfileImage.pending, (state) => {
         state.profile_loading = true
      })
      addCase(updateProfileImage.fulfilled, (state, { payload }) => {
         state.img_url = payload.image
         state.profile_loading = false
      })
      addCase(updateProfileImage.rejected, (state) => {
         toast.error(translate(319), { duration: 3000 })
         state.profile_loading = false
      })

      addCase(forgotPassword.pending, (state) => {
         state.password_loading = true
         state.password_status = 'pending'
      })
      addCase(forgotPassword.fulfilled, (state, { payload }) => {
         state.password_status = 'success'
         state.password_loading = false
      })
      addCase(forgotPassword.rejected, (state, { payload }) => {
         if(payload.status === 400){
            toast.error(translate(320), { duration: 5000 })
            // state.password_status = 'user not found'
            state.password_loading = false
         }
         state.password_status = 'rejected'
         state.password_loading = false
      })

      addCase(resetPassword.pending, (state) => {
         state.password_loading = true
         state.password_status = 'pending'
      })
      addCase(resetPassword.fulfilled, (state) => {
         state.password_status = 'reset success'
         state.password_loading = false
      })
      addCase(resetPassword.rejected, (state, { payload }) => {
         if(payload.status === 400){
            toast.error(translate(321), { duration: 4000, id: 'copy' })
            state.password_loading = false
         }
         state.password_loading = false
      })

      addCase(changePassword.pending, (state) => {
         state.password_loading = true
         state.password_status = 'pending'
      })
      addCase(changePassword.fulfilled, (state, { payload }) => {
         if(payload.message === 'Password changed successfully'){
            state.password_status = payload.message
            state.password_loading = false
         }
      })
      addCase(changePassword.rejected, (state, { payload }) => {
         if(payload.status === 400){
            state.password_loading = false
            toast.error(translate(322), { duration: 4000, id: 'copy' })
         }
      })

      addCase(changePhoneNumber.pending, (state) => {
         state.change_phone_loading = true
         state.change_phone_status = 'pending'
      })
      addCase(changePhoneNumber.fulfilled, (state, { payload }) => {
         state.user.phone_number = payload.phone_number
         state.change_phone_status = 'success'
         state.change_phone_loading = false
      })
      addCase(changePhoneNumber.rejected, (state) => {
         state.change_phone_status = 'rejected'
         state.change_phone_loading = false
      })

      addCase(deleteAccount.pending, (state) => {
         state.delete_loading = true
      })
      addCase(deleteAccount.fulfilled, (state, { payload }) => {
         if(payload.message === 'user account deleted'){
            state.delete_status = 'success'
            state.delete_loading = false
            toast.success(translate(323), { duration: 3000, id: 'copy'})
         }
      })
      addCase(deleteAccount.rejected, (state) => {
         state.delete_loading = false
         toast.success(translate(324), { duration: 3000, id: 'copy'})
      })

      addCase(getQuestionnaire.pending, (state) => {
         // state.delete_loading = true
      })
      addCase(getQuestionnaire.fulfilled, (state, { payload }) => {
         state.questionnaire = payload
      })
      addCase(getQuestionnaire.rejected, (state) => {
         // state.delete_loading = false
      })

      addCase(postQuestionnaire.pending, (state) => {
         state.questionnaire_loading = true
      })
      addCase(postQuestionnaire.fulfilled, (state, { payload }) => {
         state.submitted = true
         state.questionnaire_loading = false
         // state.questionnaire = []
         AuthService.registerQuestionnaireSubmitted(true)
         // state.show_questionnaire_popup = false
      })
      addCase(postQuestionnaire.rejected, (state) => {
         state.questionnaire_loading = false
      })
   }
 
});

export const { logOut, resetCheckUsername, setLoginError, resetLoginStatus, setRemember, setUsernameError, toggleChangePasswordModal, resetRegisterError, resetRegisterStatus, resetActivationMessage, updateToken, resetDeleteStatus, toggleQuestionnairePopup, resetSubmitted } = authSlice.actions;
export default authSlice.reducer;
