import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   viewType: 'grid',
   viewBy: 'all',
   sort_by: 'date',
   order: 'asc',
   showSideCategoryBar: false,
   mobViewByToggle: false,
}

const viewControlsSlice = createSlice({
   name: 'viewControls',
   initialState,
   reducers: {
      toggleViewType: (state, { payload }) => {
         state.viewType = payload
      },
      toggleViewBy: (state, { payload }) => {
         state.viewBy = payload
      },
      toggleSortBy: (state, { payload }) => {
         state.sort_by = payload
      },
      toggleOrderBy: (state, { payload }) => {
         state.order = payload
      },
      toggleSideCategoryBar: (state, { payload }) => {
         state.showSideCategoryBar = payload.value
      },
      toggleMobViewByToggle: (state, { payload }) => {
         state.mobViewByToggle = payload.value
      }
   }
});

export const { toggleViewType, toggleViewBy, toggleSortBy, toggleOrderBy, toggleSideCategoryBar, toggleMobViewByToggle } = viewControlsSlice.actions;
export default viewControlsSlice.reducer;