import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import myModel from "../Services/api";

const initialState = {
   chat_loading: false,
   send_loading: false,
   chats: [],
   send_msg_status: '',
   notifications: [],
   new_notification_count: 0,
   show_notification_modal: false,
   first_load: true,
   markAsSeen_status: '',
   latest_notification: false
}

export const getMessages = createAsyncThunk(
   'chat/getMessages',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.find('chat/user_messages')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const sendMessages = createAsyncThunk(
   'chat/sendMessages',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.create('chat/send_messages', '', value)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getNotifications = createAsyncThunk(
   'chat/getNotifications',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.find('my-notifications')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getNewNotifications = createAsyncThunk(
   'chat/getNewNotifications',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.find('my-notifications/new/count')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const markNotificationAsSeen = createAsyncThunk(
   'chat/markNotificationAsSeen',
   async (value, { rejectWithValue }) => {
      try {
         const response = await myModel.find('my-notification/mark-as-seen', value.notification_id)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const chatSlice = createSlice({
   name: 'chat',
   initialState,
   reducers: {
      toggle_notification_modal: (state, { payload }) => {
         state.show_notification_modal = payload.value
      },
      setFirstLoad: (state, { payload }) => {
         state.first_load = payload.firstLoad
      },
      resetMarkAsSeenStatus: (state) => {
         state.markAsSeen_status = ''
      }
   },
   extraReducers: ({ addCase }) => {
      addCase(getMessages.pending, (state) => {
         if(state.first_load) state.chat_loading = true
      })
      addCase(getMessages.fulfilled, (state, { payload }) => {
         state.chats = payload
         if(state.first_load) state.chat_loading = false
      })
      addCase(getMessages.rejected, (state) => {
         if(state.first_load) state.chat_loading = false
      })

      addCase(sendMessages.pending, (state) => {
         state.send_msg_status = 'pending'
         state.send_loading = true
      })
      addCase(sendMessages.fulfilled, (state, { payload }) => {
         if(payload.status === 'Message sent'){
            state.send_msg_status = 'success'
         }
         state.send_loading = false
      })
      addCase(sendMessages.rejected, (state) => {
         state.send_msg_status = 'rejected'
         state.send_loading = false
      })

      addCase(getNotifications.fulfilled, (state, { payload }) => {
         state.notifications = payload
      })

      addCase(getNewNotifications.fulfilled, (state, { payload }) => {
         if(payload.count > state.new_notification_count){
            state.latest_notification = true
         }else{
            state.latest_notification = false
         }
         state.new_notification_count = payload.count
      })

      addCase(markNotificationAsSeen.pending, (state) => {
         state.markAsSeen_status = 'pending'
      })
      addCase(markNotificationAsSeen.fulfilled, (state) => {
         state.markAsSeen_status = 'success'
      })
      addCase(markNotificationAsSeen.rejected, (state) => {
         state.markAsSeen_status = 'rejected'
      })
   }
});

export const { toggle_notification_modal, setFirstLoad, resetMarkAsSeenStatus } = chatSlice.actions;
export default chatSlice.reducer;