import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import myModel from '../Services/api';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import AuthService from '../Services/AuthService';

const initialState = {
  auction_loading: false,
  auction_loading2: true,
  auctionById_loading: false,
  search_loading: false,
  auctions: [],
  auctionById: {
   detail: null,
   topTenBidders: [],
  },
  auctionsCount: 0,
  auctionIsWatchlist: false,
  refresh_loading: false,
  refreshedAuctions: [],
  updatedBidId: null,
  keyword: '',
  newBidder: false,
  topTenBidders_loading: false,
  serverTime: null,
  serverTime_loading: false,
  currenTimeDiff: 0,
  nowUtcTime: null,
  showVideoModal: false,
  showGuidePrompt: false,
  how_to_use_videos: [],
  dont_show_again_guide_prompt: false,
  isLive: false,
  promptClosed: false,
  giveaway_loading: false,
  giveaway: null,
  notify_giveaway: false
}

export const loadAuctions = createAsyncThunk(
   'auction/loadAuctions',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`auctions`, args.id, args.params)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const loadAuctionById = createAsyncThunk(
   'auction/loadAuctionById',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.find(`auction`, param.id)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getWatchedList = createAsyncThunk(
   'auction/getWatchedList',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.find(`my-watchlist`, param.id)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const refreshAuctions = createAsyncThunk(
   'auction/refreshAuctions',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`auctions`, args.id, args.params)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getTopTenBidders = createAsyncThunk(
   'auction/getTopTenBidders',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`monitor/top-bids`, args.id)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getServerTime = createAsyncThunk(
   'auction/getServerTime',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`server-time`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)
export const getHowToUseVideos = createAsyncThunk(
   'auction/getHowToUseVideos',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`system/how-to-videos`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)
export const getGiveAway = createAsyncThunk(
   'auction/getGiveAway',
   async (args, { rejectWithValue } ) => {
      try {
         const response = await myModel.find(`system/popup`)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const auctionsSlice = createSlice({
   name: 'auction',
   initialState,
   reducers: {
      updateAuctions: (state, { payload: { id } }) => {
         state.auction_loading = true
         let list = state.auctions.filter(a => a.id !== id)
         state.auctions = list
         state.auction_loading = false
      },
      removeAuctionById: (state) => {
         state.auctionById = {
            detail: null,
            topTenBidders: []
         }
      },
      updateAuctionByIdPrice: (state, { payload: { auction_id, price, userId, _userId, firstName, lastName } }) => {
         if(state.auctionById.detail && auction_id === state.auctionById.detail.id){
            state.auctionById.detail.current_price = price
            
            if(userId === _userId){
               state.auctionById.detail.my_bid_value = price
            }

            const topIdx = state.auctionById.topTenBidders.findIndex(t => t.user_id === userId)
            if(topIdx >= 0) {
               const selectedBidder = state.auctionById.topTenBidders[topIdx]
               selectedBidder.amount = price
               state.auctionById.topTenBidders[topIdx] = selectedBidder
            }else{
               state.auctionById.topTenBidders = [...state.auctionById.topTenBidders, {
                  user_id: userId,
                  first_name: firstName,
                  last_name: lastName,
                  amount: price,
               }]
               state.auctionById.detail.no_of_bids = state.auctionById.detail.no_of_bids + 1
            }

         }else{}
      },
      updateAuctionPrice: (state, { payload: { auction_id, price, ws_no_of_bids } }) => {
         const idx = state.auctions.findIndex(a => a.id === auction_id);
         if (idx >= 0) {
           const selected = state.auctions[idx];
           selected.current_price = price;
           selected.no_of_bids = ws_no_of_bids + 1
           state.auctions[idx] = selected;
         }
         state.updatedBidId = auction_id;
      },
      setQuery: (state, { payload }) => {
         state.keyword = payload
      },
      setNewBidder: (state, { payload }) => {
         state.newBidder = payload
      },
      resetTopTenBidders: (state, { payload }) => {
         state.topTenBidders = []
      },
      setNowUtcTime: (state, { payload }) => {
         state.nowUtcTime = payload
      },
      resetTopTenBidderLoading: (state) => {
         state.topTenBidders_loading = false
      },
      setAuctionLoading: (state, { payload: { value }}) => {
         state.auction_loading = value
      },
      setShowVideoModal: (state, { payload: { value }}) => {
         state.showVideoModal = value
      },
      setShowGuidePrompt: (state, { payload: { value }}) => {
         state.showGuidePrompt = value
         if(value === false){
            state.promptClosed = true
         }
      },
      setDontShowAgainGuidePromptPopup: (state, { payload }) => {
         state.dont_show_again_guide_prompt = payload
      },
      updateAuctionEndTime: (state, { payload: { id, endTime } }) => {
         const idx = state.auctions.findIndex(a => a.id === id);
         if (idx >= 0) {
           const selected = state.auctions[idx];
           selected.end_time = endTime;
           state.auctions[idx] = selected;
         //   toast.success('End time extended by 1 minute due to a new bid placed in the final seconds.', { duration: 3000, id: 'copy'})
         }
      },
      updateAuctionByIdEndTime: (state, { payload: { id, endTime } }) => {
         if(state.auctionById.detail && id === state.auctionById.detail.id){
            state.auctionById.detail.end_time = endTime
            toast.success('End time extended by 1 minute due to a new bid placed in the final seconds.', { duration: 3000, id: 'copy'})
         }else{}
      },
      setLive: (state, { payload }) => {
         state.isLive = payload
      }
   },
   extraReducers: ({ addCase }) => {
      addCase(loadAuctions.pending, (state) => {
         if(state.keyword){
            state.search_loading = true
         }else{
            state.auction_loading = true
         }
      })
      addCase(loadAuctions.fulfilled, (state, { payload: { results, count } }) => {
         state.auctionIsWatchlist = false
         state.auctions = results
         if(!results.length) state.auction_loading = false
         state.auctionsCount = count
         state.search_loading = false
      })
      addCase(loadAuctions.rejected, (state) => {
         state.search_loading = false
         state.auctions = []
         state.auction_loading = false
      })

      addCase(getWatchedList.pending, (state) => {
         state.auction_loading2 = true
      })
      addCase(getWatchedList.fulfilled, (state, { payload }) => {
         if(payload.length){
            let list = payload.map(p => p.auction)
            state.auctions = list
         }else{
            state.auctions = []
         }
         state.auctionIsWatchlist = true
         state.auction_loading2 = false
      })
      addCase(getWatchedList.rejected, (state) => {
         state.auction_loading2 = false
      })

      addCase(loadAuctionById.pending, (state) => {
         state.auctionById_loading = true
      })
      addCase(loadAuctionById.fulfilled, (state, { payload }) => {
         state.auctionIsWatchlist = false
         state.auctionById.detail = payload
         state.auctionById_loading = false
      })
      addCase(loadAuctionById.rejected, (state) => {
         state.auctionById_loading = false
      })

      addCase(refreshAuctions.pending, (state) => {
         state.refresh_loading = true
      })
      addCase(refreshAuctions.fulfilled, (state, { payload: { results } }) => {
         state.refreshedAuctions = results
         state.refresh_loading = false
      })
      addCase(refreshAuctions.rejected, (state) => {
         state.refresh_loading = false
      })

      addCase(getTopTenBidders.pending, (state) => {
         state.topTenBidders_loading = true
      })
      addCase(getTopTenBidders.fulfilled, (state, { payload }) => {
         state.auctionById.topTenBidders = payload
      })
      addCase(getTopTenBidders.rejected, (state) => {
         state.topTenBidders_loading = false
      })

      addCase(getServerTime.pending, (state) => {
         state.serverTime_loading = true
      })
      addCase(getServerTime.fulfilled, (state, { payload }) => {
         const computedServerMillisecondsOffset = dayjs(payload?.datetime).diff(dayjs(), 'milliseconds');
         state.currenTimeDiff = computedServerMillisecondsOffset
         state.nowUtcTime = dayjs().add(computedServerMillisecondsOffset, 'milliseconds').unix() * 1000;
         state.serverTime = payload?.datetime
         state.serverTime_loading = false
      })
      addCase(getServerTime.rejected, (state) => {
         state.serverTime_loading = false
      })

      // addCase(getHowToUseVideos.pending, (state) => {
      //    state.topTenBidders_loading = true
      // })
      addCase(getHowToUseVideos.fulfilled, (state, { payload }) => {
         state.how_to_use_videos = payload
      })
      // addCase(getHowToUseVideos.rejected, (state) => {
      //    state.topTenBidders_loading = false
      // })

      addCase(getGiveAway.pending, (state) => {
         state.giveaway_loading = true
      })
      addCase(getGiveAway.fulfilled, (state, { payload }) => {
         console.log('Giveaway in Slice ===>', payload)
         if(payload.length){
            const existingAwardeeId = AuthService.getGiveawayId();
            let newAwardeeId = []
            if(existingAwardeeId?.length){
               const newAwardee = payload.filter(item => !existingAwardeeId.some(id => id === item.id));
               if(newAwardee?.length){
                  newAwardeeId = [...existingAwardeeId, newAwardee[0].id] 
                  state.giveaway = newAwardee[0]
                  AuthService.saveGiveawayId(newAwardeeId)
               }else{
                  state.giveaway = null
               }
            }else{
               state.giveaway = payload[0]
               AuthService.saveGiveawayId([payload[0].id])
            }
         }else{
            state.giveaway = null
         }
         state.giveaway_loading = false
      })
      addCase(getGiveAway.rejected, (state) => {
         state.giveaway_loading = false
      })
   }
});

export const { updateAuctions, removeAuctionById, updateAuctionPrice, setQuery, setNewBidder, updateAuctionByIdPrice, resetTopTenBidders, setNowUtcTime, resetTopTenBidderLoading, setAuctionLoading, setShowVideoModal, setShowGuidePrompt, setDontShowAgainGuidePromptPopup, updateAuctionEndTime, updateAuctionByIdEndTime, setLive } = auctionsSlice.actions;
export default auctionsSlice.reducer;
