import { ImSpinner2, ImSpinner9 } from 'react-icons/im';

const Loading = ({ loading, loadingStyle, min }) => {
   return (
      <div className = 'flex justify-center'>
         <div className = {`${!loading ? 'hidden': ''}`}>
            {min ?
               <ImSpinner2 className = {`animate-spin ${loadingStyle ?? ''}`} />
               :
               <ImSpinner9 className = {`animate-spin ${loadingStyle ?? ''}`} />
            }
         </div>
      </div>
   )
}

export default Loading;