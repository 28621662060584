import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CountdownTimer, opt, Pagination } from '../../utils';
import { hit_gavel, image_placeholder, sealed_icon, top_bidder_cup_1, unwatch_icon, watch_icon, zig_chereta } from '../../assets';
import dayjs from 'dayjs';
import AuthService from '../../Services/AuthService';
import translate from '../../Services/translate';
import Loading from '../common/Loading';


const AuctionList = ({ goToBidSubmission, selectedAuct, isWatched, handleWatchClick, showNotifierPopup, auctionStatuses, canSeeTopBidder }) => {

  const navigate = useNavigate();
  const token = AuthService.getToken();

  const { language } = useSelector(({ language }) => language);
  const { watchlist_loading } = useSelector(({ watchList }) => watchList);
  const { auctions, auctionIsWatchlist, auction_loading2, auctionById_loading, updatedBidId, keyword, nowUtcTime } = useSelector(({ auction }) => auction);

  const [selectedAuction, setSelectedAuction] = useState(0);

   return (
      <>
         {
            auctions.length && auctionStatuses.length
            ?
            <>
               <div className = { `text-center pb-1 hidden md:block px-2 ${auctionIsWatchlist ? 'pt-2' : ''}` }>
                  {
                     auctionStatuses.map(({ auction, current_price, startedBidding, isTopBidder, isAuctionEndingSoon, processing, hitGavel, ended }) => (
                        <div 
                           key = { auction.id } 
                           className = { `relative border border-gray-400 text-gray-600 h-[102px] mb-3 rounded-lg flex w-full shadow-md shadow-stone-400 
                           ${ended ? 'bg-black/10' : 'bg-gradient-to-b from-gray-100 to-gray-300'}` }
                        >
                           <div className = 'w-[10%] rounded-l-lg overflow-hidden h-[102px] hover:overflow-visible hover:relative pr-2'>
                              <img 
                                 // className = 'cursor-pointer w-[93%] h-[98%] object-cover hover:scale-[2] origin-left duration-200 hover:z-10 hover:absolute'
                                 className = 'cursor-pointer h-full object-contain hover:scale-[2] origin-left duration-200 hover:z-10 hover:absolute'
                                 src = { auction?.product?.thumbnail ? auction.product.thumbnail : image_placeholder } 
                                 alt = { auction?.product?.name } 
                                 onClick = { () => navigate(`/auction/${auction.id}`) }
                              />
                           </div>

                           <div className = 'border-r border-gray-400 w-[70%] relative'>
                              <div className = 'border-b border-gray-400 flex justify-between h-[55%]'>
                                 <div className = 'relative'>
                                    <h3  
                                       onClick = { () => navigate(`/auction/${auction.id}`) }
                                       className = 'text-left text-lg text-black hover:text-sky-600 cursor-pointer font-Roboto'
                                    >
                                       { auction?.product?.name }
                                    </h3>
                                    
                                    <p className = 'text-xs text-left relative'>
                                       { auction?.product?.summary ? auction.product.summary.slice(0, 120) + ' ...' : '---' }
                                    </p>

                                    {current_price && !auction?.is_closed ?
                                       <div className = {`absolute top-0 right-0 left-0 h-14 flex items-center justify-center ${showNotifierPopup && (updatedBidId === auction?.id) ? 'show-notifier-popup' : 'hide-notifier-popup'}`}>
                                          <div className = 'flex text-white h-full rounded-lg w-[90%] justify-center items-center notifier-popup px-3 bg-sky-800'>
                                             <div>
                                                { translate(266) } 
                                                <span className = 'text-lg font-bold'> {current_price && current_price?.toLocaleString('en', opt)} </span>
                                                { translate(267) }
                                             </div> 
                                          </div>
                                       </div>
                                       : 
                                       null
                                    }

                                 </div>
                              </div>

                              {isTopBidder && !auction?.is_closed ?
                                 <div 
                                    title = {`${!ended ? translate(12) : translate(13)}`}
                                    className = 'absolute right-3 top-1 flex flex-col items-center justify-center'>
                                       <img src = { top_bidder_cup_1 } alt = '' className = 'h-8 py-1 top-bidder'/>
                                       <p className = {`${!ended ? 'text-yellow-600' : 'text-lime-700'} text-sm`}>
                                          { !ended ? translate(45) : translate(46) }
                                       </p>
                                 </div>
                                 :
                                 null
                              }

                              <div className = 'flex h-[45%]'>
                                 {auction.is_closed ?
                                    <div className = 'border-r border-gray-400 w-[45%] flex items-center justify-center gap-3'>
                                       <img src = { language === 'Amharic' ? zig_chereta : language === 'Afaan-Oromoo' ? sealed_icon : sealed_icon } alt = '' className = 'h-10 w-10' />
                                       <div className = 'text-red-400 text-lg'>{ translate(14) }</div>
                                    </div>
                                    :
                                    <>
                                       <div className = 'border-r border-gray-400 w-[23%] bg-fourth-50 text-white flex flex-col justify-between'>
                                          <p className = 'text-xs text-left pl-2 truncate'>{ translate(3) }</p>

                                          {canSeeTopBidder() ?
                                             <p className = 'text-sm'>
                                                <span className = 'text-lg md:text-sm lg:text-lg font-semibold pr-2 font-Roboto'>{ current_price && current_price.toLocaleString('en', opt) }</span>
                                                { translate(4) }
                                             </p>
                                             :
                                             <p
                                                title = 'Click to go to Subscription page' 
                                                className = 'sm:text-sm lg:text-base font-semibold pr-2 text-green-300 hover:text-green-400  cursor-pointer' 
                                                onClick = { () => navigate('/subscription')}
                                             >
                                                { translate(343) }
                                             </p>
                                          }

                                       </div>
                                       
                                       <div className = 'border-r border-gray-400 w-[17%] flex flex-col justify-between'>
                                          <p className = 'text-xs text-left pl-2 truncate'>{ translate(15) }</p>
                                          <p className = 'text-sm'>
                                             <span className = 'text-base lg:text-lg font-semibold pr-2 font-Roboto'>{ auction.min_increment.toLocaleString('en', opt) }</span>
                                             { translate(4) }
                                          </p>
                                       </div>

                                       <div className = 'border-r border-gray-400 w-[18%] flex flex-col justify-between'>
                                          <p className = 'text-xs text-left pl-2 truncate'>{ translate(16) }</p>
                                          <p className = 'text-sm'>
                                             <span className = {`text-base lg:text-lg pr-2 ${language === 'Amharic' && !auction.max_increment ? 'font-normal' : 'font-semibold font-Roboto'}`}> 
                                                { auction.max_increment ? auction.max_increment.toLocaleString('en', opt) : translate(81) }
                                             </span>
                                             { auction.max_increment ? translate(4) : null }
                                          </p>
                                       </div>

                                       <div className = 'border-r border-gray-400 w-[7%] flex flex-col justify-between'>
                                          <p className = 'text-xs text-left pl-2 truncate'>{ translate(17) }</p>
                                          <p className = 'text-lg font-semibold font-Roboto'>{ auction.no_of_bids ? auction.no_of_bids : '0' }</p>
                                       </div>
                                    </>
                                 }

                                 <div className = 'border-r border-gray-400 w-[15%] flex flex-col justify-between'>
                                    <p className = 'text-xs text-left pl-2'>{ translate(20) }</p>
                                    <div className = 'text-center font-Roboto'>
                                       <CountdownTimer
                                          timestampMs = { new Date(auction.end_time).getTime() }
                                          view = 'list'
                                       />
                                    </div>
                                 </div>
                                 <div className = 'w-[20%] flex flex-col justify-between'>
                                    <p className = 'text-xs text-left pl-2'>{ translate(25) }</p>
                                    <p className = 'text-base lg:text-lg font-semibold font-Roboto'>{ dayjs(new Date(auction.end_time).toISOString()).format('h:mm a, MMM D, YYYY') }</p>
                                 </div>
                              </div>
                           </div>

                           <div className = 'w-[20%]'>
                              <div className = 'h-[55%] border-b border-gray-400 flex w-full'>
                                 <div className = 'w-1/3 border-r border-gray-400'>
                                    <p className = 'text-xs text-left pl-2'>{ translate(18) }</p>
                                    <p className = 'text-lg font-semibold pt-2 font-Roboto'>{ auction?.lot_number ? auction.lot_number : '-' }</p>
                                 </div>

                                 <div className = 'w-2/3 relative'>
                                    {/* {!auction.is_closed ?
                                       <> */}
                                          <p className = 'text-xs text-left pl-2'>{ translate(19) }</p>
                                          <p className = 'text-sm text-center pt-2'>
                                             <span className = 'text-base lg:text-lg font-semibold pr-2 font-Roboto'>{ auction.initial_price.toLocaleString('en', opt) }</span> 
                                             { translate(4) }
                                          </p>
                                       {/* </> 
                                       : 
                                       null
                                    } */}

                                    {isAuctionEndingSoon ?
                                       <div title = { translate(29) } className = {`blob absolute right-1 top-1`}></div>
                                       : 
                                       null
                                    }
                                 </div>
                              </div>

                              <div className = 'h-[45%] flex w-full'>
                                 <div className = 'flex w-full'>
                                    <div className = 'w-[13%] flex justify-center items-center border-r border-gray-400 hover:bg-gray-100'>
                                       {!auctionIsWatchlist ?
                                          <>
                                             {watchlist_loading && selectedAuction === auction.id ?
                                                <Loading loading = { watchlist_loading } loadingStyle = 'text-sky-600 w-5 h-5'/>
                                                :
                                                <img 
                                                   src = { token && isWatched(auction.id) ? unwatch_icon : watch_icon } 
                                                   alt = 'watchlist' 
                                                   className = 'w-8 md:w-9 cursor-pointer' 
                                                   title = { token && isWatched(auction.id) ? translate(27) : translate(26) }
                                                   onClick = { () => {setSelectedAuction(auction.id); handleWatchClick(auction.id)} }
                                                />
                                             }
                                          </>
                                          : 
                                          <>
                                             {(selectedAuction === auction.id) && (watchlist_loading || auction_loading2) ?
                                                <Loading loading={watchlist_loading} loadingStyle='text-sky-600 w-5 h-5'/>
                                                : 
                                                <img 
                                                   src = { unwatch_icon } 
                                                   alt = 'watchlist' 
                                                   className = 'w-8 md:w-9 cursor-pointer' 
                                                   title = { translate(27) }
                                                   onClick = { () => {setSelectedAuction(auction.id); handleWatchClick(auction.id)} }
                                                /> 
                                             }
                                          </>
                                       }
                                    </div>

                                    <div className = 'w-[35%] p-1'>
                                       <button 
                                          onClick = { () => navigate(`/auction/${auction.id}`) } 
                                          className = 'bg-primary-100 hover:bg-lime-600 h-full w-full text-white text-xs'
                                       >
                                          { translate(37) }
                                       </button>
                                    </div>

                                    <div className = 'w-[52%] p-1'>
                                       <button 
                                          className = { `rounded-br w-full h-full text-xs ${(new Date(auction.end_time).getTime() > nowUtcTime) ? 'bg-primary-100 hover:bg-lime-600 text-white' : 'bg-gray-300 text-gray-400 cursor-not-allowed'}` }
                                          disabled = { new Date(auction.end_time).getTime() < nowUtcTime }
                                          onClick = { () => goToBidSubmission(auction.id) }
                                          name = 'bid-now / update_bid'
                                       >
                                          {auctionById_loading && selectedAuct === auction.id ? 
                                             <Loading loading = { auctionById_loading } loadingStyle = 'text-white w-5 h-5'/>
                                             : 
                                             startedBidding ? translate(31) : translate(28)
                                          }
                                       </button>
                                    </div>
                                 </div>      
                              </div>
                           </div>

                           {processing ?
										<div className = 'bg-black/80 h-[88%] w-[12%] rounded-[50%] absolute right-[45%] top-[7%] flex items-center justify-center gap-3'>
											<Loading min loading = {true} loadingStyle = 'text-white w-7 h-7' />
                                 <div className = 'text-white text-lg font-semibold'>Processing</div>
										</div>
										: 
                              null
									}

                           {hitGavel ? 
                              <div className = 'absolute right-[40%] -inset-y-4 pb-3 mx-auto my-auto h-full flex items-center justify-center'>
                                 <img src = { hit_gavel } alt = '' className = 'h-40 object-cover' />
                              </div>
                              : 
                              null
                           }

                           {ended ?
                              <div 
                                 className = 'absolute inset-0 -rotate-[16deg] flex items-center justify-center text-orange-500 font-bold text-4xl h-10 w-[250px] mx-auto my-auto pb-4' 
                                 style = {{textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'}}
                              >
                                 <div className = {`${language === 'Afaan-Oromoo' ? 'tracking-[.2em]' : 'tracking-[.8em]'} h-10`}>
                                    { translate(33) }
                                 </div>
                              </div>
                              : 
                              null
                           }

                        </div>
                     ))
                  }

               </div>
               <div className = { `w-full flex justify-end ${auctions.length <= 8 || auctionIsWatchlist ? 'block md:hidden' : ''}` }>
                  <Pagination />
               </div>
            </>
            : 
            <div className = 'w-full flex justify-center pt-12 text-sky-600 font-semibold'>
               { auctionIsWatchlist ? 
                  translate(47) :
                  keyword ? 
                  translate(48) :
                  translate(49) 
               }
            </div>
         }
      </>
   )
}

export default AuctionList;
