import { useSelector } from 'react-redux';
import translate from '../../Services/translate';


const TermsAndConditions = () => {

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);

   
   return (
      <div className = 'px-0 sm:px-3'>
         <div className = 'self-center pb-1 mb-2 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(42) }</h2>
         </div>

         <div className='px-6 sm:px-12 bg-sky-50 pb-9 pt-1 font-Roboto text-gray-500'>

         <p className = 'text-base sm:text-xl font-semibold text-gray-700'>Section 1. Introduction</p>

         <p className = 'indent-5 pt-2'>This general terms and conditions agreement will govern the acquisition and usage of the services of Auction Ethiopia by the user and all business relations between the user and auction ethiopia arising from usage of our platform and/or website will be governed by this agreement.</p>

         <p className = 'indent-5 pt-2'>This general terms and conditions agreement is considered as a normal written contract signed by the client and auction ethiopia s.c in accordance with the relevant legal provisions of Ethiopian contracts law mentioned in the Ethiopian civil code, civil procedure code, commercial code and all other relevant laws. Accordingly, the agreement is enforcable upon the user and any legal entity on whose behalf the user uses the service. ex: the user's employer.</p>

         <p className = 'text-base sm:text-xl font-semibold text-gray-700 pt-3'>Section 2. Definition of terms</p>

         <p className = 'indent-5 pt-2'> For the understanding of this general terms and conditions agreement, the terms used in the agreement are defined below. Interpretation of the terms used in the agreement shall be in accordance with the definitions provided in this section. Articles written with the masculine gender shall also be taken to include the feminine gender, as the case may be.</p>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>User: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean anyone who fulfills the eligibility criterea set forth in this agreement and who accesses our platform.</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>Platform: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean the website and application of Auction Ethiopia S.c.</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>You: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean the person who has accessed the platform and who reads and agrees to this terms and conditions agreement.</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>The company: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean Auction Ethiopia S.c.</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>We, Us, Our: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean Auction Ethiopia S.c</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>Services: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean the online auction platform provided by Auction Ethiopia S.c and all related activities.</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>Sellers: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean clients of Auction Ethiopia S.c who have agreements with the company to sell their property by digital auction through the company's platform.</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>Buyers: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean users of the platform who participate in the online auction process.</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>The agreement: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean this general terms and conditions agreement.</span></div>

         <div className = 'pt-2'><strong className = 'text-sky-500 '>Subscription fee: &nbsp;</strong><span className = 'indent-5'>shall be taken to mean the fee you pay for our services according to the usage plan you selected.</span></div>

         <p className = 'text-base sm:text-xl font-semibold text-gray-700 pt-3'>Section 3. Acceptance of Terms</p>

         <p className = 'indent-5 pt-2'>When accepting this agreement, by clicking and checking the box indicating your acceptance, you agree to the terms of this agreement and to be governed by them. If you are entering into this agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind such entity to these terms and conditions. If you donot have such authority, you must not accept this agreement and may not use our services.</p>

         <p className = 'indent-5 pt-2'>By accessing and using the Platform, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions set forth in this Agreement. If you do not agree to these terms, you may not use the Platform.</p>

         <p className = 'text-base sm:text-xl font-semibold text-gray-700 pt-3'>Section 4. Registration and Subscription </p>

         <h3 className = 'text-gray-600 pt-3'><strong>4.1 Eligibility</strong></h3>

         <p className = 'indent-5 pt-2'>The user must be at least 18 years old and have the legal capacity to enter into this agreement. By registering on the platform, the user represents and warrants that he/she meets these requirements.</p>

         <h3 className = 'text-gray-600 pt-3'><strong>4.2 Registration</strong></h3>

         <p className = 'indent-5 pt-2'>Inorder to use our platform, the user must create a user account and provide accurate and complete information. You must provide your accurate and legal personal information. You (the user) are solely responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

         <h3 className = 'text-gray-600 pt-3'><strong>4.3 Subscription</strong></h3>

         <p className = 'indent-5 pt-2'>The use of our platform is subscription based. By subscribing to our services, you agree to pay the applicable fees according to the pricing plan that you selected. The subscription fees are non-refundable, except as expressly stated otherwise in this agreement or reqired by relevant and applicable law.</p>

         <p className = 'text-base sm:text-xl font-semibold text-gray-700 pt-3'>Section 5. Usage of the platform</p>

         <h3 className = 'text-gray-600 pt-3'><strong>5.1 Platform services</strong></h3>

         <div className = 'pt-2'><strong className = 'text-gray-600'>{`a)`} &nbsp;</strong><span className = 'indent-5'>Auction Ethiopia as middle person: The Platform acts as an intermediary to facilitate the auction process between sellers and buyers. We provide a digital platform where sellers can list items for sale, and buyers can participate in auctions, view items, place bids, and complete transactions.
         </span></div>
         <div className = 'pt-2'><strong className = 'text-gray-600'>{`b)`} &nbsp;</strong><span className = 'indent-5'>Verification of items: We make reasonable efforts to verify the existence and quality of items listed on the Platform. However, we do not guarantee the accuracy, authenticity, or quality of the items. It is the buyer's responsibility to conduct due diligence and verify the condition and specifications of the items before placing bids or making purchases.
         </span></div>

         <h3 className = 'text-gray-600 pt-3'><strong>5.2 License</strong></h3>

         <p className = 'indent-5 pt-2'>We grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Platform for its intended purpose, subject to the terms and conditions of this Agreement. Any other usage or activitity that goes against the intended purpose of the platform and any attempt to license, tranfer to third party or replicate the platform will result in legal prosecution of the infringer by the company.</p>

         <h3 className = 'text-gray-600 pt-3'><strong>5.3 Prohibited activities</strong></h3>

         <p className = 'indent-5 pt-2'>While using our plarform and services, the client agrees not to engage in any of the following activities:</p>

         <div className = 'pt-2'><strong className = 'text-gray-600'>{`a)`} &nbsp;</strong><span className = 'indent-5'>Violating any applicable laws or regulations.</span></div>

         <div className = 'pt-2'><strong className = 'text-gray-600'>{`b)`} &nbsp;</strong><span className = 'indent-5'>Interfering with or disrupting the integrity or performance of the Platform.</span></div>

         <div className = 'pt-2'><strong className = 'text-gray-600'>{`c)`} &nbsp;</strong><span className = 'indent-5'>Attempting to gain unauthorized access to the Platform or its related systems or networks.
         </span></div>

         <div className = 'pt-2'><strong className = 'text-gray-600'>{`d)`} &nbsp;</strong><span className = 'indent-5'>Engaging in any fraudulent, deceptive, or illegal activity.</span></div>

         <div className = 'pt-2'><strong className = 'text-gray-600'>{`e)`} &nbsp;</strong><span className = 'indent-5'>Uploading or transmitting any harmful or malicious content, including viruses or malware.</span></div>

         <h3 className = 'text-gray-600 pt-3'><strong>5.4 Auction listings</strong></h3>

         <p className = 'indent-5 pt-2'>The Platform displays auction listings from various government and private agencies. The listings include item descriptions, images, starting bids, and relevant auction details. While we strive to provide accurate information, we do not guarantee the accuracy, completeness, or authenticity of the listings.You acknowledge that any bids or purchases made on the Platform are solely at your own risk.</p>

         <h3 className = 'text-gray-600 pt-3'><strong>5.5 Bidding and purchase</strong></h3>

         <p className = 'indent-5 pt-2'>By participating in an auction, you agree to comply with the specific terms and conditions set by the seller. Placing a bid constitutes a binding agreement between you and the seller. The highest bidder at the close of the auction is typically awarded the item, subject to the seller's approval. It is your responsibility to review and understand the seller's terms before participating in an auction.</p>

         <h3 className = 'text-gray-600 pt-3'><strong>5.6 Transaction completion</strong></h3>

         <p className = 'indent-5 pt-2'>If your bid is successful, you are obligated to complete the purchase by paying the agreed-upon price and any applicable fees. The company may require you to appear in person at the company’s office inorder to verify your identity and present you with a winner’s certificate that you will present to the seller upon making final payment. The Platform will facilitate the sale process, but we are not a party to the transaction between you and the seller. We do not guarantee the seller's performance, nor do we guarantee the condition or quality of the purchased item.</p>

         <p className = 'text-base sm:text-xl font-semibold text-gray-700 pt-3'>Section 6. Update of terms and conditions</p>

         <p className = 'indent-5 pt-2'>The terms and conditions set forth in this agreement maybe updated by the company at any time. The company may change, edit or update the terms of this agreement with or without notice. It is the user's responsibility to revisit and read the terms and conditions regularly. By continuing to use our platform you acknowledge that you accept any and all changes or updates to the terms of this agreement.</p>

         <p className = 'text-base sm:text-xl font-semibold text-gray-700 pt-3'>Section 7. Ownership</p>

         <p className = 'indent-5 pt-2'>The Platform, including its content, features, and functionality, is owned by Auction Ethiopia S.C and is protected by intellectual property and copyright laws of Ethiopia. You agree not to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from or through the Platform.</p>

         <p className = 'text-base sm:text-xl font-semibold text-gray-700 pt-3'>Section 8. Limitation of Liability</p>

         <p className = 'indent-5 pt-2'>The platform is provided on an "As Is" and "As Available" basis. We make no representations or warranties of any kind, whether express or implied.</p>

         <div className = 'w-full flex justify-center py-8'>
            <p className = 'border-t border-stone-500 text-center w-[50%]'></p>
         </div>

         </div>
      </div>
   )
}

export default TermsAndConditions;