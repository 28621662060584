import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthService from '../Services/AuthService';


const PrivateRoute = () => {

    const location = useLocation();
    const token = AuthService.getToken();

    return (
        token 
            ? <Outlet /> 
            : <Navigate to = '/login' state = {{ from: location }} replace />
    )
}

export default PrivateRoute;
