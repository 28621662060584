import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import AuthService from './AuthService';
import { base_url } from './apiConstants';
import { useDispatch } from 'react-redux';
import { logOut, updateToken } from '../features/authSlice';
import { useNavigate } from 'react-router-dom';

const AxiosInstance = axios.create({
  baseURL: base_url,
  proxy: false,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// a request interceptor
AxiosInstance.interceptors.request.use(
  async config => {    
    const authTokens = AuthService.getToken();
    if (authTokens) {
      const jwt = jwt_decode(authTokens.token.access)
      const isExpired = dayjs.unix(jwt.exp).diff(dayjs()) < 1;
      if(!isExpired) {
        config.headers.Authorization = `Bearer ${authTokens.token.access}`;
        return config
      }
      
      const response = await axios.post(`${base_url}token/refresh/`, { refresh: authTokens.token.refresh });
      if(response){
        const dispatch = useDispatch();
        dispatch(updateToken({...authTokens, token: { ...authTokens.token, access: response.data.access }}))
        AuthService.setToken({...authTokens, token: { ...authTokens.token, access: response.data.access }})
        config.headers.Authorization = `Bearer ${response.data.access}`
        return config
      }
    }   
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

// a response interceptor
AxiosInstance.interceptors.response.use(
  response => {
    return response.data
  },
  (error) => {
    if (!error.response) {
      toast.error('Network Error!', { duration: 3000, id: 'copy' });
    }
    else if (error.response.status === 422){
      toast.error('Please fill out all the required fields!', { id: 'copy' });
    }
    else if (error.response.status === 400){
      const dispatch = useDispatch();
      const navigate = useNavigate();
      dispatch(logOut())
      navigate('/login')
      toast.error('Your session has expired, please login', { duration: 5000, id: 'copy' });
    }
    return Promise.reject(error.response);
  },
);

// eslint-disable-next-line
export default {
  get: AxiosInstance.get,
  post: AxiosInstance.post,
  put: AxiosInstance.put,
  delete: AxiosInstance.delete,
  patch: AxiosInstance.patch
};