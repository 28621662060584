import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   language: localStorage.getItem('language') ?? 'English'
}

export const languageSlice = createSlice({
   name: 'language',
   initialState,
   reducers: {
      setLanguage: (state, { payload: { lang } }) => {
         localStorage.setItem('language', lang)
         state.language = lang
      }
   }
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;