import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setMinMaxPageLimit } from '../features/paginationSlice';
import translate from '../Services/translate';


const Pagination = () => {

   const dispatch = useDispatch();

   // eslint-disable-next-line
   const { currentPage, limit, query, mobPage, displayPageNumbers, minPageLimit, maxPageLimit } = useSelector(({ pagination }) => pagination);
   const { auctionsCount } = useSelector(({ auction }) => auction);

   const pages = [];

   for(let i = 1; i <= Math.ceil(auctionsCount / limit ); i++){
      pages.push(i)
   }

   const handleCurrentPage = num => {
      dispatch(setCurrentPage({ num }))
   }

   const pageNumbers = pages.map(page => {
      if(page <= maxPageLimit  && page > minPageLimit) {
         return(
            <span 
               key = { page } 
               id = { page } 
               onClick = { () => handleCurrentPage(page) }
               className = { `text-xs hover:bg-gradient-to-b hover:from-gray-300 hover:to-gray-500 bg-gradient-to-b from-gray-100 to-gray-300 focus:bg-blue-500  hover:text-white px-2 md:px-3 py-1 border border-gray-400 focus:text-white cursor-pointer ${currentPage === page ? 'bg-gradient-to-b from-sky-400 to-sky-600 text-white border border-sky-600' : ''}` }
            >
               { page }
            </span>
         );
      }else{
         return null;
      }
   });

   const handlePrevious = () => {
      if((currentPage - 1) % displayPageNumbers === 0){
         dispatch(setMinMaxPageLimit({ min: minPageLimit, max: maxPageLimit, e: 'prev' }))
      }
      dispatch(setCurrentPage({ num: currentPage - 1 }))
   }

   const handleNext = () => {
      if(currentPage + 1 > maxPageLimit){
         dispatch(setMinMaxPageLimit({ min: minPageLimit, max: maxPageLimit, e: 'next' }))
      }
      dispatch(setCurrentPage({ num: currentPage + 1 }));
   }

   return (
      <>
         <div className = { `${ auctionsCount <= limit ? 'hidden' : 'hidden md:block pr-4' }` }>
            <nav className = 'pt-2 md:pt-2 flex items-center'>
               <p className = 'text-xs text-gray-500 pr-3'>{ `Page ${currentPage} of ${pages.length}` } </p>
               <div className = 'ml-2 flex items-start'>

                  <button 
                     className = { `rounded border border-gray-500 px-2 text-xs py-1 hover:text-sky-600 hover:border-sky-600 bg-gradient-to-b
                     from-gray-100 to-gray-300 ${currentPage > 1 ? 'block' : 'hidden'}` } 
                     onClick = { () => handlePrevious() } >&lt; &nbsp; { translate(329) }
                  </button>

                  <span className = { `flex items-center px-2 text-lg h-4 ${minPageLimit >= 1 ? 'visible' : 'invisible'}` }>&hellip;</span>

                  { pageNumbers }

                  <span className = { `flex items-center px-2 text-lg h-4 ${pages.length > maxPageLimit ? 'visible' : 'invisible'}` }>&hellip;</span>

                  <button 
                     className = { `rounded border border-gray-500 px-2 text-xs py-1 hover:text-sky-600 hover:border-sky-600 bg-gradient-to-b
                     from-gray-100 to-gray-300 ${currentPage === Math.ceil(auctionsCount/limit) ? 'invisible' : ''}` } 
                     onClick = { () => handleNext() } >{ translate(330) } &nbsp; &gt;
                  </button>
               </div>
            </nav>
         </div>

         <div className = 'block md:hidden w-full sm:mb-2'>
            <div className = { `flex w-full justify-between px-1` }>
               <div className = { `pt-2 w-full text-left ${auctionsCount <= limit ? 'hidden' : 'block md:hidden'}  ${mobPage < 2 ? 'hidden' : ''}` }>
                  <button onClick = { () => handleCurrentPage(mobPage - 1) } className = 'text-xs bg-primary-100 border border-secondary-50 rounded w-28 sm:w-32 text-white py-1 sm:py-2'>{`<<`} &nbsp; &nbsp; 
                  { translate(329) }
                  </button>
               </div>
               <div className = { `pt-2 w-full text-right ${auctionsCount <= limit ? 'hidden' : 'block md:hidden'}  ${currentPage === Math.ceil(auctionsCount/limit) ? 'hidden' : ''}` }>
                  <button onClick = { () => handleCurrentPage(mobPage + 1) } className = 'text-xs bg-primary-100 border border-secondary-50 rounded w-28 sm:w-32 text-white py-1 sm:py-2'>
                     { translate(330) } &nbsp; &nbsp; { `>>` }
                  </button>
               </div>
            </div>
         </div>
      </>
   )
}

export default Pagination;