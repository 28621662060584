import { useState, useLayoutEffect, useEffect, Children, memo, cloneElement } from 'react';
import usePrevious from '../../hooks/usePrevious'
import calculateBoundingBoxes from './calculateBoundingBoxes';

// const AnimateTopBidders = memo(({ isScrolling, setIsScrolling, children }) => {
const AnimateTopBidders = memo(({ children }) => {
   const [boundingBox, setBoundingBox] = useState({});
   const [prevBoundingBox, setPrevBoundingBox] = useState({});
   // const [animatingBidderId, setAnimatingBidderId] = useState(null);
   // const [animatingBidderIndex, setAnimatingBidderIndex] = useState(null);
   const [highestBidderIndex, setHighestBidderIndex] = useState(null);
   // const [isAnimating, setIsAnimating] = useState(false);
   const prevChildren = usePrevious(children);
   // let isAnimating = false

   // Find the index of the child with the highest bid
   useEffect(() => {
      const timer = setTimeout(() => {
         
         let highestBid = -Infinity;
         let highestBidderIdx = null;
         children.forEach((child, index) => {
            if (child.props.amount > highestBid) {
               highestBid = child.props.amount;
               highestBidderIdx = index;
            }
         });
         setHighestBidderIndex(highestBidderIdx);
      }, 150);
      return () => clearTimeout(timer)
   }, [children]);

   useLayoutEffect(() => {
      const newBoundingBox = calculateBoundingBoxes(children);
      setBoundingBox(newBoundingBox);
   }, [children]);

   useLayoutEffect(() => {
         const prevBoundingBox = calculateBoundingBoxes(prevChildren);
         setPrevBoundingBox(prevBoundingBox);
   }, [prevChildren]);

   useEffect(() => {
      const hasPrevBoundingBox = Object.keys(prevBoundingBox).length;
       // Define isAnimating variable
      if (hasPrevBoundingBox) {
         let animating = false;
         Children.forEach(children, (child, index) => {
            const domNode = child.ref.current;
            const firstBox = prevBoundingBox[child.key];
            const lastBox = boundingBox[child.key];
            const changeInY = firstBox?.top - lastBox?.top;

            if (changeInY) {
               animating = true;
               // setIsAnimating(true)
               // setAnimatingBidderId(child.key);
               requestAnimationFrame(() => {
                  // Before the DOM paints, invert child to old position
                  domNode.style.transform = `translateY(${changeInY}px)`;
                  domNode.style.transition = "transform 0s";

                  requestAnimationFrame(() => {
                     // After the previous frame, remove
                     // the transistion to play the animation
                     domNode.style.transform = "";
                     domNode.style.transition = "transform 1000ms";
                     // setTimeout(() => {
                     //    // isAnimating = false
                     //    setHighestBidderIndex(null);
                     // }, 1000);
                     // setAnimatingBidderId(null);
                     // setAnimatingBidderIndex(index);
                     // setTimeout(() => {
                     //    setAnimatingBidderIndex(null);
                     // }, 500);
                  });
               });
            }
         });
         if (!animating) {
            setHighestBidderIndex(null); // Reset highestBidderIndex when there is no animation
         }
      }
      // return () => setIsScrolling(false);

       // eslint-disable-next-line
   }, [boundingBox, prevBoundingBox, children]);

   // return children;
   return Children.map(children, (child, index) =>
      cloneElement(child, { isAnimating: index === highestBidderIndex })
    );
});

export default AnimateTopBidders;

// import { useState, useLayoutEffect, useEffect, Children, memo } from 'react';
// import usePrevious from '../../hooks/usePrevious';

// const AnimateTopBidders = memo(({ topBidders, children }) => {
//   const [currentOrder, setCurrentOrder] = useState([]);
//   const [prevOrder, setPrevOrder] = useState([]);
//   const prevChildren = usePrevious(children);

//   useLayoutEffect(() => {
//     const newOrder = [];
//     topBidders.forEach((bidder) => {
//       const child = children.find((child) => child.key === bidder.id);
//       if (child) {
//         newOrder.push(child);
//       }
//     });
//     setCurrentOrder(newOrder);
//   }, [topBidders, children]);

//   useLayoutEffect(() => {
//     setPrevOrder(prevChildren);
//   }, [prevChildren]);

//   useEffect(() => {
//     const hasPrevOrder = prevOrder?.length;

//     if (hasPrevOrder) {
//       Children.forEach(currentOrder, (child, index) => {
//         const prevChild = prevOrder[index];
//         const changeInIndex = child.key - prevChild.key;

//         if (changeInIndex) {
//           requestAnimationFrame(() => {
//             // Before the DOM paints, invert child to old position
//             child.style.transform = `translateY(${changeInIndex * 100}px)`;
//             child.style.transition = 'transform 0s';

//             requestAnimationFrame(() => {
//               // After the previous frame, remove
//               // the transistion to play the animation
//               child.style.transform = '';
//               child.style.transition = 'transform 500ms ease-in-out';
//             });
//           });
//         }
//       });
//     }
//   }, [currentOrder, prevOrder, topBidders, children]);

//   return children;
// });

// export default AnimateTopBidders;
