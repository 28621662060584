import { useDispatch, useSelector } from 'react-redux';
import { toggleOrderBy, toggleSortBy } from '../../features/viewControlsSlice';
import { IoCaretDownSharp, IoCaretUpSharp } from 'react-icons/io5';
import translate from '../../Services/translate';


const SortByToggle = () => {

  const dispatch = useDispatch();

  // eslint-disable-next-line
  const { language } = useSelector(({ language }) => language);
  const { sort_by, order } = useSelector(({ viewControls }) => viewControls);

  
  return (
      <div className = 'flex justify-end pb-1 pr-2'>
        <div className = 'items-center flex pt-3 pb-1'>
          <p className = 'text-gray-700 pr-2 text-sm'>{translate(307)}</p>

          <button
            onClick = { () => dispatch(toggleSortBy('date')) }
            className = { `${language === 'Amharic' ? 'text-xs py-[6px] w-28' : 'w-32 text-sm py-1'} border rounded-l-xl border-r-0 bg-gray-200
            ${sort_by === 'date' ?
            'bg-gray-500 text-white border-gray-500' :
            'border-gray-400 hover:border-sky-600 hover:bg-white hover:text-sky-600'}` }
          >
            { translate(25) }
          </button>

          <button
            onClick = { () => dispatch(toggleSortBy('bids')) }
            className = { `${language === 'Amharic' ? 'text-xs py-[6px] w-28' : 'text-sm py-1 w-32'} border border-r-0 bg-gray-200
            ${sort_by === 'bids' ?
            'bg-gray-500 text-white border-gray-500' :
            'border-gray-400 hover:border-sky-600 hover:bg-white hover:text-sky-600'}` }
          >
            {translate(17)}
          </button>

          <button
            onClick = { () => dispatch(toggleSortBy('current_price')) }
            className = { `${language === 'Amharic' ? 'text-xs py-[6px] w-28' : 'text-sm py-1 w-52'} border border-r-0 bg-gray-200
            ${sort_by === 'current_price' ?
            'bg-gray-500 text-white border-gray-500' :
            'border-gray-400 hover:border-sky-600 hover:bg-white hover:text-sky-600'}` }
          >
            { translate(3) }
          </button>

          <button
            onClick = { () => dispatch(toggleSortBy('initial_price')) }
            className = { `w-28 ${language === 'Amharic' ? 'text-xs py-[6px]' : 'text-sm py-1'} border rounded-r-xl bg-gray-200
            ${sort_by === 'initial_price' ?
            'bg-gray-500 text-white border-gray-500' :
            'border-gray-400 hover:border-sky-600 hover:bg-white hover:text-sky-600'}` }
          >
            { translate(19) }
          </button>



          <button
            onClick = {() => dispatch(toggleOrderBy(`asc`))}
            className = {`px-2 py-1 text-lg border border-gray-400 rounded-l-xl ml-2  
            ${order === 'asc' ? 'bg-gray-500 text-white' : 'bg-gray-200 hover:bg-white hover:text-sky-600 hover:border-sky-600'}`}
          >
            <IoCaretUpSharp />
          </button>

          <button
            onClick = { () => dispatch(toggleOrderBy(`dsc`)) }
            className = { `px-2 py-1 text-lg border border-gray-400 rounded-r-xl ml-1 
            ${order === 'dsc' ? 'bg-gray-500 text-white' : 'bg-gray-200 hover:bg-white hover:text-sky-600 hover:border-sky-600'}` }
          >
            <IoCaretDownSharp />
          </button>
        </div>
      </div>
  );
};

export default SortByToggle;