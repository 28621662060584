import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMyPurchases } from '../../features/purchaseSlice';
import translate from '../../Services/translate';
import ViewTypeToggle from '../viewControls/ViewTypeToggle';
import PurchasesGrid from './PurchasesGrid';
import PurchasesList from './PurchasesList';
import Loading from '../common/Loading';


const Purchases = () => {

  const dispatch = useDispatch();

  // eslint-disable-next-line
  const { language } = useSelector(({ language }) => language);
  const { viewType } = useSelector(({ viewControls }) => viewControls );
  const { purchase_loading, purchases } = useSelector(({ purchase }) => purchase);


  useEffect(() => {
    dispatch(loadMyPurchases())
    // eslint-disable-next-line
  }, [])


  return (
    <>
      <div className = 'self-center pb-1 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
        <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(130) }</h2>
      </div>
      {
        purchase_loading
        ?
        <div className = 'pt-12'><Loading loading = { purchase_loading } loadingStyle = 'text-sky-600 w-7 h-7'/> </div>
        :
        <>
          {purchases ?
            <div className = 'px-3'>
              <ViewTypeToggle />
              
              {viewType === 'grid' ?
                <PurchasesGrid />
                :
                <PurchasesList />
              }
            </div>
            :
            null
          }
        </>
      }
    </>
  )
}

export default Purchases;
